<template>
    <header>
      <nav v-if="token" class="mobile-navbar-contents">
        
            <div class="swingi-logo">
                <RouterLink to="/">
                    <img src="/swingi.png" alt="Swingi logo" />
                  </RouterLink>
            </div>
            
            <div class="userInfo">
                <button @click="handleClick">
                  <div class="flex items-center">
                    <img :src="proImage" alt="User Icon" class="mobile-profile" />
                    <h4 :class="{
                      'text-xs': true,
                      'max-w-150px': true,
                      'overflow-hidden': true,
                      'text-ellipsis': true,
                      'whitespace-nowrap': true,
                    }" style="padding: 5px;">
                    {{ user }}
                  </h4>
                  </div>
                </button>
              </div>
        
        
        
        
       
            
              <RouterLink to="/Store" class="hamburg">
                <div class="actions">
                  
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                      <path d="M3.00003 10.9871V15.4925C3.00003 18.3243 3.00003 19.7403 3.87871 20.62C4.75739 21.4998 6.1716 21.4998 9.00003 21.4998H15C17.8284 21.4998 19.2426 21.4998 20.1213 20.62C21 19.7403 21 18.3243 21 15.4925V10.9871" stroke="currentColor" stroke-width="1.5" />
                      <path d="M15 16.9768C14.3159 17.584 13.2268 17.9768 12 17.9768C10.7732 17.9768 9.68412 17.584 9.00003 16.9768" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                      <path d="M17.7957 2.50294L6.14986 2.53202C4.41169 2.44248 3.96603 3.78259 3.96603 4.43768C3.96603 5.02359 3.89058 5.87774 2.82527 7.4831C1.75996 9.08846 1.84001 9.56536 2.44074 10.6767C2.93931 11.5991 4.20744 11.9594 4.86865 12.02C6.96886 12.0678 7.99068 10.2517 7.99068 8.97523C9.03254 12.1825 11.9956 12.1825 13.3158 11.8157C14.6386 11.4483 15.7717 10.1331 16.0391 8.97523C16.195 10.4142 16.6682 11.2538 18.0663 11.8308C19.5145 12.4284 20.7599 11.515 21.3848 10.9294C22.0097 10.3439 22.4107 9.04401 21.2968 7.6153C20.5286 6.63001 20.2084 5.7018 20.1033 4.73977C20.0423 4.18234 19.9888 3.58336 19.5972 3.20219C19.0248 2.64515 18.2036 2.47613 17.7957 2.50294Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                    
                  
                  Store
                </div>
              </RouterLink>
          

            <div class="moneyBtn" @click="handleClick2">
                <div v-if="!isGettingSP" class="moneyBox" >
                  {{ sPoint }}
                </div>
                <div v-else class="moneyBox" >
                  <Loading type="submit" :width="'20px'"/>
                </div>
                <div>
                  <svg fill="#ff5555" width="20px" height="20px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#ff5555">
    
                    <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                    
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                    
                    <g id="SVGRepo_iconCarrier"> <title>point</title> <path d="M16 4.686l-11.314 11.314 11.314 11.314 11.314-11.314-11.314-11.314zM10.343 16l5.657-5.657 5.657 5.657-5.657 5.657-5.657-5.657z"/> </g>
                    
                    </svg>
                </div>
                
              </div>

              <div class="actions2">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="black" fill="none">
                  <rect x="10.5" y="3" width="3" height="3" rx="1" stroke="currentColor" stroke-width="1.5" />
                  <rect x="10.5" y="10.5" width="3" height="3" rx="1" stroke="currentColor" stroke-width="1.5" />
                  <rect x="10.5" y="18" width="3" height="3" rx="1" stroke="currentColor" stroke-width="1.5" />
              </svg>
                <button @click="showMore">More</button>
              </div>

              <div v-if="vueMore">
                <div class="actions2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
                    <path d="M15 17.625C14.9264 19.4769 13.3831 21.0494 11.3156 20.9988C10.8346 20.987 10.2401 20.8194 9.05112 20.484C6.18961 19.6768 3.70555 18.3203 3.10956 15.2815C3 14.723 3 14.0944 3 12.8373L3 11.1627C3 9.90561 3 9.27705 3.10956 8.71846C3.70555 5.67965 6.18961 4.32316 9.05112 3.51603C10.2401 3.18064 10.8346 3.01295 11.3156 3.00119C13.3831 2.95061 14.9264 4.52307 15 6.37501" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                    <path d="M21 12H10M21 12C21 11.2998 19.0057 9.99153 18.5 9.5M21 12C21 12.7002 19.0057 14.0085 18.5 14.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                  <button @click="disconnect">LogOut</button>
                </div>
                <div class="actions3">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="black" fill="none">
                    <path d="M14 8L10.6678 11.0203C9.77741 11.8273 9.77741 12.1727 10.6678 12.9797L14 16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="1.5" />
                </svg>
                  <button @click="showLess">Less</button>
                </div>
              </div>
        
              

      </nav>
      <nav v-else class="mobile-navbar-contents">
          <div class="swingi-logo">
              <RouterLink to="/">
                  <img src="/swingi.png" alt="Swingi logo" />
                </RouterLink>
          </div>
          
          <div class="userInfo">
              <button @click="handleClick">
                <div class="flex items-center">
                  
                  <h4 :class="{
                    'text-xs': true,
                    'max-w-150px': true,
                    'overflow-hidden': true,
                    'text-ellipsis': true,
                    'whitespace-nowrap': true,
                  }" style="padding: 5px;">
                  Login | Signin
                </h4>
                </div>
              </button>
            </div>
      </nav>
    </header>
  </template>
  
  <script>
  import Loading from '../components/items/loading.vue';

  export default {
    name: "MobileNavbar",

    components: {
      Loading,
  },
  
    props: {
      userId: {
        type: String,
        required: true
      },
      username: {
        type: String,
        required: true
      },
    },
    data() {
      return {
        isGettingSP: true,
        user: 'start',
        proImage: localStorage.getItem('proImage') ? localStorage.getItem('proImage') : '/g.png',
        sPoint: 0,
        vueMore: false,
      };
    },

    created() {
    
    //token: localStorage.getItem('authToken');
    //email: localStorage.getItem('userEmail');
    if(localStorage.getItem('authToken') != null){
      
      this.token = localStorage.getItem('authToken');
      if(localStorage.getItem('userName')) {
        this.user = localStorage.getItem('userName');
      } else {
        this.user = this.token;
      }
      if(localStorage.getItem('proImage')) {
        this.proImage = localStorage.getItem('proImage');
      } else {
        this.proImage = '/g.png';
      }

      setTimeout(() => {
        this.getUserBank();
      }, 5000);

    
    }  
  
    },


    mounted() {
    this.searchUserImage();
  },
  
    methods: {
      showMore() {
        this.vueMore = true;
      },

      showLess() {
        this.vueMore = false;
      },

      handleClick() {
        this.$router.push({ name: 'UserD', params: { id: this.userId } });
      },
      disconnect() {
        localStorage.clear();
        window.location.href = this.$router.resolve({ name: 'Home' }).href;
      },

      wait(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        async revealEffect() {
          const characters = '0123456789';
            let randomText = '';
            
            const currentText = this.sPoint;

            
              for (let i = 0; i < currentText.length; i++) {
                // Si une lettre du mot de passe existe, on l'affiche
                let randomL = '';
                let randomL2 = '';

                
                randomL += characters.charAt(Math.floor(Math.random() * characters.length));
                this.sPoint = randomText + randomL;
                await this.wait(40);
                randomL2 += characters.charAt(Math.floor(Math.random() * characters.length));
                this.sPoint = randomText;
                this.sPoint = randomText + randomL2;
                await this.wait(40);
                randomText += currentText[i];  // Affiche la vraie lettre
                

                // Met à jour le texte affiché
              this.sPoint = randomText;

            }


            
        },

      async getUserBank(){
        this.isGettingSP = true;
        try {
      const response = await fetch('https://swingi.pro/api.php?action=getBankDetails');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const info = await response.json();
        
        const foundTokenIndex = info.data.findIndex(item => item.token === localStorage.getItem('authToken'));
        
        console.log('found Token Index : ', info.data[foundTokenIndex].sPoint);  

        if (foundTokenIndex !== -1) {

          
          this.sPoint = info.data[foundTokenIndex].sPoint;
          this.sCoin = info.data[foundTokenIndex].sCoin;
          localStorage.setItem('sPoint', this.sPoint);
          this.revealEffect();
          
          
        } else {
          console.log('No Bank Yet');
        }
        this.isGettingSP = false;
      }catch (error){
        this.isGettingSP = false;
        console.error(error);
      }
        
    },

    async searchUserImage(){
      if (localStorage.getItem('authToken')) {
      const response = await fetch('https://swingi.pro/api.php?action=getUserDetails');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const info = await response.json();
        
        const foundTokenIndex = info.data.findIndex(item => item.token === localStorage.getItem('authToken'));
        

        if (this.foundTokenIndex !== -1) {
          
          if (info.data[foundTokenIndex].userName !== '' && info.data[foundTokenIndex].userName !== null) {
            this.user = info.data[foundTokenIndex].userName;
            this.proImage = info.data[foundTokenIndex].profileImage;
            localStorage.setItem('proImage', this.proImage);
          } else {
            this.user = info.data[foundTokenIndex].token;
          }
          
        }
      } else {
      this.isGettingSP = false;
      this.sPoint = 0;
    }
  }
  }
  };
  </script>
  
  <style scoped>
  .mobile-navbar-contents {
    position: fixed;
    width: 100%;
    top: 10px;
    z-index: 10000;
  }

  .swingi-logo{
    position: fixed;
    top: 10px;
    left: 0px;
    padding: 2px;
    width: 120px;
    height: 45px;
    margin-inline: 10px;
    background: rgba(255, 255, 255, 0.8);
    border: 2px solid rgba(255, 255, 255, .2);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 10px #7fadc760;
    border-radius: 10px;
    z-index: 10000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */

  }


  
  .mobile-profile {
    top: 10px;
    right: 0px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 5px;
    
  }
  
  .userInfo {
    position: fixed;
    top: 10px;
    right: 0px;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
    border: 2px solid rgba(255, 255, 255, .2);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 10px #7fadc760;
    border-radius: 10px;
    font-family: 'Josefin Sans', sans-serif;
    padding-inline: 5px;
    margin-inline: 10px;
    max-width: 150px;
    min-width: 68px;
    height: 45px;
    font-family: 'Josefin Sans', sans-serif;/*Playwrite CU*/
    font-weight: bold;
    z-index: 10000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */

  }

  .svg-icons {
    margin-right: 5px;
    margin-left: 5px;
    width: 20px;
  }

  .storeImg {
  
    margin: 5px;
    width: 20px;
  }
  
  .actions {
    position: fixed;
    top: 55px;
    left: 0px;
    margin-top: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
    border: 2px solid rgba(255, 255, 255, .2);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 10px #7fadc760;
    border-radius: 10px;
    font-family: 'Josefin Sans', sans-serif;
    z-index: 10000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */
    margin-inline: 10px;
    font-size: 1.2vh;

  }

  .actions2 {
    position: fixed;
    top: 100px;
    left: 0px;
    margin-top: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
    border: 2px solid rgba(255, 255, 255, .2);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 10px #7fadc760;
    border-radius: 10px;
    font-family: 'Josefin Sans', sans-serif;
    z-index: 10000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */
    margin-inline: 10px;
    font-size: 1.2vh;

  }

  .actions3 {
    position: fixed;
    top: 145px;
    left: 0px;
    margin-top: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
    border: 2px solid rgba(255, 255, 255, .2);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 10px #7fadc760;
    border-radius: 10px;
    font-family: 'Josefin Sans', sans-serif;
    z-index: 10000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */
    margin-inline: 10px;
    font-size: 1.2vh;

  }


  .moneyBtn{
    position: fixed;
    top: 55px;
    right: 0px;
    margin-top: 10px;
    margin-inline: 10px;
    background: rgba(255, 255, 255, 0.8);
    border: 2px solid rgba(255, 255, 255, .9);
    backdrop-filter: blur(2px);
    border-radius: 10px;
    display: flex;
    justify-content: space-between; /* Répartit les éléments avec de l'espace entre eux */
    align-items: center; /* Centre les éléments verticalement */
    font-size: 14px;
    padding-right: 5px;
    padding-left: 5px;
    max-width: 35%;
    height: 30px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    box-shadow: 0 0 10px #7fadc760;
  }
  
  .moneyBtn:hover {
    background-color: #ff5555;
    color: #fff;
    transform: scale(1.05);
  }
  
  .moneyBox{
    font-size: 12px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .moneyBtn svg {
    fill: currentColor;
    stroke: currentColor;
    color: #ff5555;
  }

  .moneyBtn:hover  svg {
    fill: currentColor;
    stroke: currentColor;
    color: #fff;
  }
  </style>
  