<template>
  
    <div class="body1">
      

      <div class="profile-container">
        <div v-if="notificationVisible" class="notification">{{ notificationMessage }}</div>
        <div v-if="errorVisible" class="notification-error">{{ errorMessage }}</div>
        <div class="profile-box-image">
          <img 
            :src="profileImage" 
            alt="Profile Image" 
            
            class="profile-image"
          />
          
          <div :style="{
            width: '30px', 
            height: '30px', 
            position: 'absolute', 
            bottom: '10px', 
            right: '10px', 
            backgroundColor: '#ff5555', 
            borderRadius: '50%', 
            border: '3px solid whitesmoke',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'}" @click="triggerFileInput">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" color="white" fill="none">
              <path d="M5 21C9.20998 16.2487 13.9412 9.9475 21 14.6734" stroke="currentColor" stroke-width="1.5" />
              <path d="M17 4.50012C17.4915 3.99442 18.7998 2.00012 19.5 2.00012M22 4.50012C21.5085 3.99442 20.2002 2.00012 19.5 2.00012M19.5 2.00012V10.0001" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M20.9999 13C20.998 17.147 20.9472 19.2703 19.6088 20.6088C18.2175 22 15.9783 22 11.5 22C7.02166 22 4.78249 22 3.39124 20.6088C2 19.2175 2 16.9783 2 12.5C2 8.02166 2 5.78249 3.39124 4.39124C4.78249 3 7.02166 3 11.5 3C11.6699 3 14 3.00008 14 3.00008" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
          </svg>
          </div>
          <input 
            type="file" 
            ref="fileInput" 
            @change="uploadImage" 
            style="display: none;"
          />
        </div>
      </div>

      <div class="userBtn1" >
        <div class="moneyBtn1">
          <div class="moneyBox1">
            {{ sPoint }}
            <div>
              <svg fill="#ff5555" width="30px" height="30px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#ff5555">
    
                <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                
                <g id="SVGRepo_iconCarrier"> <title>point</title> <path d="M16 4.686l-11.314 11.314 11.314 11.314 11.314-11.314-11.314-11.314zM10.343 16l5.657-5.657 5.657 5.657-5.657 5.657-5.657-5.657z"/> </g>
                
                </svg>
            </div>
          </div>
        </div>
      </div>
      
     
          
          <div class="user-title" >
            
            <div v-if="updatingName" :style="{width: '100%'}">
              
              <input v-model="userName" :style="{width: '100%', height: '40px'}"/>
              <div v-if="!isSavingName" class="editing">
                <div class="btn" :style="{width: '45%'}" @click="updateName">
                  Save
                </div>
                <div class="btn" :style="{width: '45%'}" @click="cancel">
                  Cancel
                </div>
              </div>
              <div v-else style="display: flex; justify-content: center; align-items: center; margin-block: 15px;">

                <Loading/>
              </div>
              
              
            </div>

            <div v-else class="user-name">
              <div :style="{display: 'flex', width: '90%', borderRadius: '15px', backgroundColor: 'whitesmoke', alignItems: 'center', justifyContent: 'space-between'}">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="black" fill="none">
                  <path d="M6.57757 15.4816C5.1628 16.324 1.45336 18.0441 3.71266 20.1966C4.81631 21.248 6.04549 22 7.59087 22H16.4091C17.9545 22 19.1837 21.248 20.2873 20.1966C22.5466 18.0441 18.8372 16.324 17.4224 15.4816C14.1048 13.5061 9.89519 13.5061 6.57757 15.4816Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M16.5 6.5C16.5 8.98528 14.4853 11 12 11C9.51472 11 7.5 8.98528 7.5 6.5C7.5 4.01472 9.51472 2 12 2C14.4853 2 16.5 4.01472 16.5 6.5Z" stroke="currentColor" stroke-width="1.5" />
              </svg>
              <div :style="{width: '100%', display: 'flex', justifyContent: 'center'}">
                {{ userName }}
              </div>
                
              </div>

              <div :style="{
                width: '30px',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                marginInline: '5px',
                paddingInline: '10px',
                cursor: 'pointer',
                textAlign: 'center',
                alignItems: 'center',
                backgroundColor: 'whitesmoke',
                borderRadius: '5px',
              }"
                :class="{ hoverEffect: isHovered1 }"
                @mouseover="isHovered1 = true"
                @mouseleave="isHovered1 = false" @click="updateName">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="black" fill="none">
                  <path d="M15.2141 5.98239L16.6158 4.58063C17.39 3.80646 18.6452 3.80646 19.4194 4.58063C20.1935 5.3548 20.1935 6.60998 19.4194 7.38415L18.0176 8.78591M15.2141 5.98239L6.98023 14.2163C5.93493 15.2616 5.41226 15.7842 5.05637 16.4211C4.70047 17.058 4.3424 18.5619 4 20C5.43809 19.6576 6.94199 19.2995 7.57889 18.9436C8.21579 18.5877 8.73844 18.0651 9.78375 17.0198L18.0176 8.78591M15.2141 5.98239L18.0176 8.78591" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M11 20H17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
              </svg>
              
              </div>
              
            </div>
            
          </div>

          <div class="email-title">

            <div v-if="updatingEmail" :style="{width: '100%'}">
              <input v-model="email" :style="{width: '100%', height: '40px'}"/>
              <div v-if="!isSavingEmail" class="editing">
                <div class="btn" :style="{width: '45%'}" @click="updateEmail">
                  Save
                </div>
                <div class="btn" :style="{width: '45%'}" @click="cancel">
                  Cancel
                </div>
              </div>
              <div v-else style="display: flex; justify-content: center; align-items: center; margin-block: 15px;">

                <Loading/>
              </div>
              
            </div>

              <div v-else class="email">
                <div :style="{display: 'flex', width: '90%', borderRadius: '15px', backgroundColor: 'whitesmoke', alignItems: 'center', justifyContent: 'space-between'}">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="black" fill="none">
                    <path d="M2 6L8.91302 9.91697C11.4616 11.361 12.5384 11.361 15.087 9.91697L22 6" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                    <path d="M2.01577 13.4756C2.08114 16.5412 2.11383 18.0739 3.24496 19.2094C4.37608 20.3448 5.95033 20.3843 9.09883 20.4634C11.0393 20.5122 12.9607 20.5122 14.9012 20.4634C18.0497 20.3843 19.6239 20.3448 20.7551 19.2094C21.8862 18.0739 21.9189 16.5412 21.9842 13.4756C22.0053 12.4899 22.0053 11.5101 21.9842 10.5244C21.9189 7.45886 21.8862 5.92609 20.7551 4.79066C19.6239 3.65523 18.0497 3.61568 14.9012 3.53657C12.9607 3.48781 11.0393 3.48781 9.09882 3.53656C5.95033 3.61566 4.37608 3.65521 3.24495 4.79065C2.11382 5.92608 2.08114 7.45885 2.01576 10.5244C1.99474 11.5101 1.99475 12.4899 2.01577 13.4756Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                </svg>
                <div :style="{width: '90%', display: 'flex', justifyContent: 'center', fontSize: '2vh'}">
                  {{ email }}
                </div>
                  
                </div>

                <!--<div :style="{
                  width: '30px',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  marginInline: '5px',
                  paddingInline: '10px',
                  cursor: 'pointer',
                  textAlign: 'center',
                  alignItems: 'center',
                  backgroundColor: 'whitesmoke',
                  borderRadius: '5px',
                }"
                  :class="{ hoverEffect: isHovered2 }"
                  @mouseover="isHovered2 = true"
                  @mouseleave="isHovered2 = false" @click="updateEmail">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="black" fill="none">
                    <path d="M15.2141 5.98239L16.6158 4.58063C17.39 3.80646 18.6452 3.80646 19.4194 4.58063C20.1935 5.3548 20.1935 6.60998 19.4194 7.38415L18.0176 8.78591M15.2141 5.98239L6.98023 14.2163C5.93493 15.2616 5.41226 15.7842 5.05637 16.4211C4.70047 17.058 4.3424 18.5619 4 20C5.43809 19.6576 6.94199 19.2995 7.57889 18.9436C8.21579 18.5877 8.73844 18.0651 9.78375 17.0198L18.0176 8.78591M15.2141 5.98239L18.0176 8.78591" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M11 20H17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                </svg>
                
                </div>
              -->
                
                
              </div>
              
              

            
          </div>

          <div class="phone-title">

            <div v-if="updatingPhone" :style="{width: '100%'}">
              <input v-model="phoneNumber" 
              type="tel"
              :style="{ width: '100%', height: '40px' }"
              @input="validatePhoneNumber"/>
              <div v-if="!isSavingPhone" class="editing">
                <div class="btn" :style="{width: '45%'}" @click="updatePhone">
                  Save
                </div>
                <div class="btn" :style="{width: '45%'}" @click="cancel">
                  Cancel
                </div>
              </div>
              <div v-else style="display: flex; justify-content: center; align-items: center; margin-block: 15px;">

                <Loading/>
              </div>
              
            </div>

            <div v-else class="phone">
              <div :style="{display: 'flex', width: '90%', borderRadius: '15px', backgroundColor: 'whitesmoke', alignItems: 'center', justifyContent: 'space-between'}">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="black" fill="none">
                  <path d="M5 9C5 5.70017 5 4.05025 6.02513 3.02513C7.05025 2 8.70017 2 12 2C15.2998 2 16.9497 2 17.9749 3.02513C19 4.05025 19 5.70017 19 9V15C19 18.2998 19 19.9497 17.9749 20.9749C16.9497 22 15.2998 22 12 22C8.70017 22 7.05025 22 6.02513 20.9749C5 19.9497 5 18.2998 5 15V9Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                  <path d="M11 19H13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M9 2L9.089 2.53402C9.28188 3.69129 9.37832 4.26993 9.77519 4.62204C10.1892 4.98934 10.7761 5 12 5C13.2239 5 13.8108 4.98934 14.2248 4.62204C14.6217 4.26993 14.7181 3.69129 14.911 2.53402L15 2" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
              </svg>
              <div :style="{width: '100%', display: 'flex', justifyContent: 'center'}">
                {{ phoneNumber }}
              </div>
              

             
              </div>
              <div :style="{
                width: '30px',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                marginInline: '5px',
                paddingInline: '10px',
                cursor: 'pointer',
                textAlign: 'center',
                alignItems: 'center',
                backgroundColor: 'whitesmoke',
                borderRadius: '5px',
              }"
                :class="{ hoverEffect: isHovered3 }"
                @mouseover="isHovered3 = true"
                @mouseleave="isHovered3 = false" @click="updatePhone">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="black" fill="none">
                  <path d="M15.2141 5.98239L16.6158 4.58063C17.39 3.80646 18.6452 3.80646 19.4194 4.58063C20.1935 5.3548 20.1935 6.60998 19.4194 7.38415L18.0176 8.78591M15.2141 5.98239L6.98023 14.2163C5.93493 15.2616 5.41226 15.7842 5.05637 16.4211C4.70047 17.058 4.3424 18.5619 4 20C5.43809 19.6576 6.94199 19.2995 7.57889 18.9436C8.21579 18.5877 8.73844 18.0651 9.78375 17.0198L18.0176 8.78591M15.2141 5.98239L18.0176 8.78591" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M11 20H17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
              </svg>
              
              </div>
              
              
          </div>

          
            
          </div>


          



    </div>
</template>



<script>

import Loading from '../components/items/loading.vue';

export default {
    name: "UserCardInfo",

    components: {
      Loading
    },

    data() {
    return {
      profileImage: '/g.png', // URL par défaut de l'image
      sPoint: 0,
      userName: '',
      email: '',
      phoneNumber: '',
      notificationMessage: '',
      notificationVisible: false,
      errorVisible: false,
      token: localStorage.getItem('authToken'), // Assurez-vous de définir le jeton approprié
      notification: '', // Notification message
      errorMessage: '', // Error message
      notificationType: '', // 'success' or 'error'
      updatingName: false,
      updatingEmail: false,
      updatingPhone: false,
      isSavingName: false,
      isSavingEmail: false,
      isSavingPhone: false,
      isHovered1: false,
      isHovered2: false,
      isHovered3: false,
      nm: '',
      em: '',
      ph: '',
    };
  },
  created() {
    this.searchUserImage();
  },
  methods: {
    validatePhoneNumber(event) {
      // Autoriser uniquement les chiffres, espaces, parenthèses, et autres caractères valides pour un téléphone
      const cleanedValue = event.target.value.replace(/[^0-9+\s()-]/g, "");
      this.phoneNumber = cleanedValue;
    },

    triggerFileInput() {
      this.$refs.fileInput.click(); // Ouvre le sélecteur de fichiers
    },

    wait(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        async revealEffect() {
          const characters = '0123456789';
            let randomText = '';
            
            const currentText = this.sPoint;

            
              for (let i = 0; i < currentText.length; i++) {
                // Si une lettre du mot de passe existe, on l'affiche
                let randomL = '';
                let randomL2 = '';

                
                randomL += characters.charAt(Math.floor(Math.random() * characters.length));
                this.sPoint = randomText + randomL;
                await this.wait(40);
                randomL2 += characters.charAt(Math.floor(Math.random() * characters.length));
                this.sPoint = randomText;
                this.sPoint = randomText + randomL2;
                await this.wait(40);
                randomText += currentText[i];  // Affiche la vraie lettre
                

                // Met à jour le texte affiché
              this.sPoint = randomText;

            }


            
        },

    async searchUserImage(){
      const response = await fetch('https://swingi.pro/api.php?action=getUserDetails');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const info = await response.json();
        
        const foundTokenIndex = info.data.findIndex(item => item.token === localStorage.getItem('authToken'));
        console.log('found Token : ', info.data[foundTokenIndex].profileImage);
        

        if (this.foundTokenIndex !== -1) {
          
          if (info.data[foundTokenIndex].profileImage !== '' && info.data[foundTokenIndex].profileImage !== null) {
            const timestamp = new Date().getTime();
            this.profileImage = `${info.data[foundTokenIndex].profileImage}?t=${timestamp}`;
            localStorage.setItem('proImage', this.profileImage);
          } else {
            const timestamp = new Date().getTime();
            this.profileImage = `/g.png?t=${timestamp}`;
            localStorage.setItem('proImage', this.profileImage);
          }
          if (info.data[foundTokenIndex].userName !== '' && info.data[foundTokenIndex].userName !== null) {
            this.userName = info.data[foundTokenIndex].userName;
          } else {
            this.userName = info.data[foundTokenIndex].token;
          }

          if (info.data[foundTokenIndex].email !== '' && info.data[foundTokenIndex].email !== null) {
            this.email = info.data[foundTokenIndex].email;
          } else {
            this.email = 'Add Your Email';
          }

          if (info.data[foundTokenIndex].phoneNumber !== '0' && info.data[foundTokenIndex].phoneNumber !== null) {
            this.phoneNumber = info.data[foundTokenIndex].phoneNumber;
          } else {
            this.phoneNumber = 'Add Your Phone Number';
          }
          
        }

        this.sPoint = localStorage.getItem('sPoint');
        this.revealEffect();
    },
    async uploadImage() {
      const fileInput = this.$refs.fileInput;
      const formData = new FormData();
      formData.append('fileToUpload', fileInput.files[0]);
      formData.append('token', this.token);

      try {
        const response = await fetch('https://swingi.pro/api.php?action=uploadImage', {
          method: 'POST',
          body: formData
        });

        // Lire la réponse en tant que texte pour le débogage
        const textResponse = await response.text();

        // Convertir la réponse en JSON
        const data = JSON.parse(textResponse);
        if (data.success) {
          
          const timestamp = new Date().getTime();
          this.profileImage = `${data.imageUrl}?t=${timestamp}`;// Mettez à jour l'URL de l'image
          this.showNotification('Image uploaded successfully');
          await this.updateUser(this.token, 'profileImage', `https://swingi.pro${this.profileImage}`);
        } else {
          this.showError(data.message);
        }
      } catch (error) {
        this.showError(`Error uploading image: ${error}`);
      }
    },
    showError(message) {
      this.errorMessage = message;
      this.errorVisible = true;

      // Masquer la notification après 3 secondes
      setTimeout(() => {
        this.errorVisible = false;
      }, 10000);
    },
    showNotification(message) {
      this.notificationMessage = message;
      this.notificationVisible = true;

      // Masquer la notification après 3 secondes
      setTimeout(() => {
        this.notificationVisible = false;
      }, 10000);
    },



    async updateUser(token, field, value) {
      const updateData = {
        token: token,
        field: field,
        value: value,// Assignez l'URL de l'image téléchargée
      };

      try {
        const response = await fetch('https://swingi.pro/api.php?action=updateUserDetails', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(updateData)
        });
        const data = await response.json();
        if (data.success) {
          console.log('User updated successfully');
        } else {
          console.error('Error updating user:', data.error);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },

    async updateName() {
      
      
      if (this.updatingName) {
        this.isSavingName = true;
        await this.updateUser(this.token, 'userName', this.userName);
        localStorage.setItem('userName', this.userName);
        this.isSavingName = false;
      } else {
        
        this.isSyncing();
      }
      this.updatingName = !this.updatingName;

      
    },
    async updateEmail() {
      
      
      if (this.updatingEmail) {
        this.isSavingEmail = true;
        await this.updateUser(this.token, 'email', this.email);
        localStorage.setItem('userEmail', this.email);
        this.isSavingEmail = false;
      } else {
        
        this.isSyncing();
      }
      this.updatingEmail= !this.updatingEmail;

      
    },

    async updatePhone() {

      if (this.updatingPhone) {
        this.isSavingPhone = true;
        await this.updateUser(this.token, 'phoneNumber', this.phoneNumber);
        this.isSavingPhone = false;
      } else {
        
        this.isSyncing();
      }
      this.updatingPhone= !this.updatingPhone;
      

    },

    isSyncing() {
      if (this.nm === '') {
        this.nm = this.userName;
      } else {
        this.userName = this.nm;
      }

      if (this.em === '') {
        this.em = this.email;
        
      } else {
        this.email = this.em;
      }
      if (this.ph === '') {
        this.ph = this.phoneNumber;
        
      } else {
        this.phoneNumber = this.ph;
      }
    },

    cancel() {
      this.isSyncing();

      this.updatingName= false;
      this.updatingEmail= false;
      this.updatingPhone= false;
    }
  }
}


</script>

<style>




.body1 {
    
    width: 30%; /* Prendre toute la largeur du parent */
    min-width: 300px;
    border-radius: 6px;
    background: white;
    border: 1px solid rgba(255, 255, 255, .8);
    box-shadow: 0 0 10px #7fadc760;
    padding-block: 20px;
    

  }

  .profile-container {
    position: relative;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    background-color: whitesmoke;
  }


  
  
  .notification {
    position: absolute;
    top: -40px; /* Ajuster pour placer le message au-dessus de l'image */
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(115, 220, 115, 0.316);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 10;
    animation: fadeOut 10s forwards;
  }

  .notification-error {
    position: absolute;
    top: -40px; /* Ajuster pour placer le message au-dessus de l'image */
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(224, 100, 100, 0.316);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 10;
    animation: fadeOut 10s forwards;
  }
  
  @keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }

  .profile-box-image{
    position: relative;
    

  }



  .svg-box{
    width: 25px;
    height: 25px;
    position: relative;
    cursor: pointer;
    border-radius: 50%;
    background-color: #ff5555;
  }



  .profile-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;

    
  }

  .svg {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-inline: 5px;
  }

  .user-title {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    padding: 2px;
    font-family: 'Playwrite CU', sans-serif;
    font-size: 18px; /* Ajuster la taille de la police selon vos besoins */

  }

  .editing {
    display: flex;
    justify-content: space-between; /* Center horizontally */
    align-items: center; /* Center vertically */

  }

  .email-title {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    padding: 2px;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 16px;

  }

  .phone-title {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    padding: 2px;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 14px;

  }

  .user-name {
    width: 100%;
    display: flex;
    justify-content: space-between; /* Center horizontally */
    align-items: center; /* Center vertically */
    white-space: nowrap;        /* Empêche le retour à la ligne */
    overflow: hidden;           /* Cache le texte qui dépasse */
    text-overflow: ellipsis; 
    
    color: black; /* Ajuster la couleur du texte selon vos besoins */
    
    
  }

  .email {
    width: 100%;
    display: flex;
    justify-content: space-between; /* Center horizontally */
    align-items: center;
    white-space: nowrap;        /* Empêche le retour à la ligne */
    overflow: hidden;           /* Cache le texte qui dépasse */
    text-overflow: ellipsis;    /* Ajoute des points de suspension "..." */
  }
  .phone {
    width: 100%;
    display: flex;
    justify-content: space-between; /* Center horizontally */
    align-items: center;
    white-space: nowrap;        /* Empêche le retour à la ligne */
    overflow: hidden;           /* Cache le texte qui dépasse */
    text-overflow: ellipsis;    /* Ajoute des points de suspension "..." */
  }

  .title {

    padding: 10px;
    font-size: 12px; /* Ajuster la taille de la police selon vos besoins */
    color: black; /* Ajuster la couleur du texte selon vos besoins */

  }

  .text {
    width: 60%;
    color: black; /* Ajuster la couleur du texte selon vos besoins */

  }

  .child {
    width: 100%;
    height: 40px;

    padding: 10px;
    display: flex;
    justify-self: center;
    align-items: center;

  }

  .input {
    width: 40%;
    height: 35px;

  }
  
  .input input:hover, 
  .input input:focus {
    width: 100%;
    height: 30px;
    background: rgba(255, 255, 255, .9);
    border: none;
    outline: none;
    border-radius: 2px;
    padding-left: 2px;
    font-size: 12px; /* Changez la taille de la police ici */
  }

  .input input:not(:focus) {
    padding-left: 2px;
    font-size: 12px; /* Taille de police lorsque l'élément perd le focus */
    border-color: black;
  }
  
  .input input::placeholder {
    padding-left: 2px;
    color: gray;
    font-size: 12px; /* Changez la taille de la police du placeholder ici */
  }



  .boxBtn {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 30px; /* Ajuster la hauteur selon vos besoins */
    background-color: #00A3FF80; /* Couleur de fond */
    color: black; /* Couleur du texte */
    border: none; /* Enlever les bordures par défaut */
    border-radius: 2px; /* Bordure arrondie */
    cursor: pointer; /* Curseur pointeur */
    text-align: center; /* Centrer le texte */
  }
  
  .btn:hover {
    background-color: #00A3FF; /* Couleur de fond lors du survol */
  }


  .input input {
    width: 100%;
    height: 30px;
    background: rgba(212, 212, 212, 0.6);
    border: none;
    outline: none;
    border-radius: 2px;
    font-size: 16px;

  }

  .userBtn1 {

    display: flex;
    justify-content: center; /* Répartit les éléments avec de l'espace entre eux */
    align-items: center; /* Centre les éléments verticalement */
  
  }

  .moneyBtn1{
    margin-block: 5px;
    background-color: rgba(118, 187, 180, 0.2);
    border: 2px solid rgba(255, 255, 255, .9);
    backdrop-filter: blur(2px);
    border-radius: 10px;
    display: flex;
    justify-content: center; /* Répartit les éléments avec de l'espace entre eux */
    align-items: center; /* Centre les éléments verticalement */
    font-size: 18px;
    padding-right: 5px;
    padding-left: 5px;
    
    height: 60px;
    max-width: 250px;
    min-width: 100px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  
  
  .moneyBtn1:hover {
    background-color: #ff5555;
    color: #fff;
    transform: scale(1.05);
  }
  
  .moneyBox1{
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  
  .moneyBtn1 svg {
    fill: currentColor;
    stroke: currentColor;
  }

</style>