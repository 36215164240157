<template>
  <div class="checkbox-container" @click="toggleCheck" >
    <input type="checkbox" :checked="isChecked" class="checkbox-input"  />
    <span class="custom-checkbox"></span>
    <div v-if="label" :style="{marginInline: '5px'}">{{ label }}</div>
  </div>
</template>

<script>
export default {
  name: "CheckBox",
  props: {
    label: {
      type: String,
      default: "Check me"
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isChecked: this.value,
      token: localStorage.getItem('authToken'), 
      instaQst: [],
    };
  },
  watch: {
    value(newValue) {
      this.isChecked = newValue;
    },

  },

  methods: {
    toggleCheck() {
      this.isChecked = this.value;
      this.$emit("input", this.isChecked); // Assurez-vous que la valeur est émise ici
      //this.updateInstaValue();
    },




  }
};
</script>

<style scoped>
.checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-family: 'Josefin Sans', sans-serif;
}

.checkbox-input {
  display: none;
}

.custom-checkbox {
  width: 15px;
  height: 15px;
  border: 2px solid #ccc;
  background-color: red;
  border-radius: 50%; /* Makes the checkbox round */
  position: relative;
  transition: all 0.3s ease;
}

.custom-checkbox::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  border: solid #5d9000;
  border-width: 0 2px 2px 0;
  opacity: 0;
  transform: translate(-50%, -50%) rotate(45deg) scale(0);
  transition: all 0.3s ease;
}

.checkbox-input:checked + .custom-checkbox {
  background-color: #5d9000;
  border-color: #5d9000;
}

.checkbox-input:checked + .custom-checkbox::after {
  opacity: 1;
  transform: translate(-50%, -50%) rotate(45deg) scale(1);
}

label {
  margin-left: 10px;
}
</style>
