<template>
  <div class="body3">
    <div :style="{height: '40px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}">
      <div :style="{display: 'flex', alignItems: 'center', marginLeft: '5px'}">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#ff6767" fill="none">
          <path d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
          <path d="M16.5 12C16.5 14.4853 14.4853 16.5 12 16.5C9.51472 16.5 7.5 14.4853 7.5 12C7.5 9.51472 9.51472 7.5 12 7.5C14.4853 7.5 16.5 9.51472 16.5 12Z" stroke="currentColor" stroke-width="1.5" />
          <path d="M17.5078 6.5L17.4988 6.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
        <p class="instaTitle">
          Instagram
        </p>
      </div>

      <div :style="{display: 'flex', fontSize: '1.5vh', fontFamily: 'Josefin Sans, sans-serif', color: '#7fadc7', fontWeight: 'bold'}">
        <div :style="{
          width: '30px',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          marginInline: '5px',
          paddingInline: '10px',
          cursor: 'pointer',
          textAlign: 'center',
          alignItems: 'center',
          backgroundColor: 'whitesmoke',
          borderRadius: '5px',
        }"
        :class="{ hoverEffect: isHovered0 }"
          @mouseover="isHovered0 = true"
          @mouseleave="isHovered0 = false" @click="viewCard">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#7fadc7" fill="none">
            <path d="M14 3.5C17.7712 3.5 19.6569 3.5 20.8284 4.7448C22 5.98959 22 7.99306 22 12C22 16.0069 22 18.0104 20.8284 19.2552C19.6569 20.5 17.7712 20.5 14 20.5L10 20.5C6.22876 20.5 4.34315 20.5 3.17157 19.2552C2 18.0104 2 16.0069 2 12C2 7.99306 2 5.98959 3.17157 4.7448C4.34315 3.5 6.22876 3.5 10 3.5L14 3.5Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
            <path d="M5 15.5C6.60865 13.3626 10.3539 13.246 12 15.5M10.249 10.2501C10.249 11.2166 9.46552 12.0001 8.49902 12.0001C7.53253 12.0001 6.74902 11.2166 6.74902 10.2501C6.74902 9.28362 7.53253 8.50012 8.49902 8.50012C9.46552 8.50012 10.249 9.28362 10.249 10.2501Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
            <path d="M15 9.5L19 9.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
            <path d="M15 13.5H17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
        </svg>
        
        </div>
        
        
        <div :style="{
          width: '30px',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          marginInline: '5px',
          paddingInline: '10px',
          cursor: 'pointer',
          textAlign: 'center',
          alignItems: 'center',
          backgroundColor: 'whitesmoke',
          borderRadius: '5px',
        }"
          :class="{ hoverEffect: isHovered1 }"
          @mouseover="isHovered1 = true"
          @mouseleave="isHovered1 = false" @click="viewStat">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#7fadc7" fill="none">
            <path d="M21 21H10C6.70017 21 5.05025 21 4.02513 19.9749C3 18.9497 3 17.2998 3 14V3" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
            <path d="M7 4H8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
            <path d="M7 7H11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
            <path d="M5 20C6.07093 18.053 7.52279 13.0189 10.3063 13.0189C12.2301 13.0189 12.7283 15.4717 14.6136 15.4717C17.8572 15.4717 17.387 10 21 10" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        
        </div>
        <div :style="{
          width: '30px',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          marginInline: '5px',
          paddingInline: '10px',
          cursor: 'pointer',
          textAlign: 'center',
          alignItems: 'center',
          backgroundColor: 'whitesmoke',
          borderRadius: '5px',
        }"
          :class="{ hoverEffect: isHovered2 }"
          @mouseover="isHovered2 = true"
          @mouseleave="isHovered2 = false" @click="changeInsta">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#7fadc7" fill="none">
            <path d="M16.9767 19.5C19.4017 17.8876 21 15.1305 21 12C21 7.02944 16.9706 3 12 3C11.3126 3 10.6432 3.07706 10 3.22302M16.9767 19.5V16M16.9767 19.5H20.5M7 4.51555C4.58803 6.13007 3 8.87958 3 12C3 16.9706 7.02944 21 12 21C12.6874 21 13.3568 20.9229 14 20.777M7 4.51555V8M7 4.51555H3.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        
        </div>
          <div :style="{
            width: '30px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            marginInline: '5px',
            paddingInline: '10px',
            cursor: 'pointer',
            textAlign: 'center',
            alignItems: 'center',
            backgroundColor: '#ff5555',
            borderRadius: '5px',
          }"
            :class="{ hoverEffect: isHovered3 }"
            @mouseover="isHovered3 = true"
            @mouseleave="isHovered3 = false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="white" fill="none">
              <path d="M15.2141 5.98239L16.6158 4.58063C17.39 3.80646 18.6452 3.80646 19.4194 4.58063C20.1935 5.3548 20.1935 6.60998 19.4194 7.38415L18.0176 8.78591M15.2141 5.98239L6.98023 14.2163C5.93493 15.2616 5.41226 15.7842 5.05637 16.4211C4.70047 17.058 4.3424 18.5619 4 20C5.43809 19.6576 6.94199 19.2995 7.57889 18.9436C8.21579 18.5877 8.73844 18.0651 9.78375 17.0198L18.0176 8.78591M15.2141 5.98239L18.0176 8.78591" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M11 20H17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
          </svg>
          
          </div>

          <div :style="{width: '30px', marginInline: '10px', paddingInline: '10px'}">
          
          </div>

          
          
          
          
      </div>
      
    </div>
    <div :style="{width: '100%', display: 'flex', justifyContent: 'center'}" 
    >
      <div class="instaViewBox" :class="{ hoverEffect3: isHoveredPro }"
      @mouseover="isHoveredPro = true"
      @mouseleave="isHoveredPro = false">
        <div class="instaImage">
          <img :src="instaImage" alt="Profile Picture" class="profileImage">
        </div>
        <div class="instaName">
          <p class="userTitle">{{ instaUser }}</p>
        </div>
      </div>
    </div>
    

    <div v-if="isEditing">
      <form>
        <ul>
          <li>
            <div class="structure-box">
              <div class="dscr">
                <text>Your Insta Account Country :</text>
              </div>           
             
              <div class="dropdown">
                <div class="dropbtn" @click="toggleDropdown">{{ dDContent }}</div>
                <div class="dropdown-content" v-if="isDropdownOpen">
                  <a v-for="country in countries" :key="country.name" @click.prevent="updateContent(country.name)">
                    <img :src="country.flag" alt="Flag of {{ country.name }}" class="flag-icon" />
                    {{ country.name }}
                  </a>
                </div>
              </div>
            </div>
            

            <div class="structure-box">
              <div class="dscr">
                <text>Searching Description :</text>
              </div>
              <div class="short-text">
                <text> - Whrite Short Description To Explane What To Search For Getting Trafics On Your Account</text>
              </div>
              <div class="short-text">
                <text>ex: search on instagrame for how to get money for free with Swingi</text>
              </div>  
             
              <input
                type="text"
                ref="autoResizeInput"
                v-model="sDescription"
                @input="autoResizeInput"
              />
            </div>

            <div class="structure-box">
              <div class="dscr">
                <text>Simple Quastion :</text>
              </div>
              <div class="short-text">
                <text>- Whrite Simple Question Basing On Your Searching Description</text>
              </div>  
              <div class="short-text">
                <text>ex: in my video how many times I say Swingi</text>
              </div>  
             
              <input
                type="text"
                ref="autoResizeInput"
                v-model="sQst"
                @input="autoResizeInput"
              />
            </div>

            

            

            <div class="structure-box">
              <div class="dscr">
                <text>Correct Answer :</text>
              </div>
              <div class="short-text">
                <text>- Whrite Your Correct Answer Here</text>
              </div>  
              <div class="short-text">
                <text>ex: 3 Times</text>
              </div>  
             
              <input
                type="correct-answer"
                ref="autoResizeInput"
                v-model="cAnswer"
                @input="autoResizeInput"
              />
            </div>

            

            <div class="structure-box">
              <div class="dscr">
                <text>Wrong Answer 1 :</text>
              </div>
              <div class="short-text">
                <text>- Whrite Your Wrong Answer 1 Here</text>
              </div> 
              <div class="short-text">
                <text>ex: 2 Times</text>
              </div>  
             
              <input
                type="wrong-answer"
                ref="autoResizeInput"
                v-model="wAnswer1"
                @input="autoResizeInput"
              />
            </div>

           

            <div class="structure-box">
              <div class="dscr">
                <text>Wrong Answer 2 :</text>
              </div>
              <div class="short-text">
                <text>- Whrite Your Wrong Answer 2 Here</text>
              </div> 
              <div class="short-text">
                <text>ex: Once</text>
              </div>  
             
              <input
                type="wrong-answer"
                ref="autoResizeInput"
                v-model="wAnswer2"
                @input="autoResizeInput"
              />
            </div>

            <div class="structure-box2">
              <!-- Utilisation de v-model pour lier la valeur de la checkbox -->
              <CheckBox v-model="isChecked" label="Activate After Saving" @input="isAccepted" :value="isChecked"  />

            </div>



            

          </li>
        </ul>

        <div v-if="!isSaving" :style="{
          maxWidth: '100%',
          height: '50px',
          display: 'flex',
          justifyContent: 'center',
          marginInline: '5px',
          paddingInline: '10px',
          cursor: 'pointer',
          textAlign: 'center',
          alignItems: 'center',
          
          borderRadius: '5px',
          color: '#7fadc7'
        }" 
        :class="{hoverEffect2: isHoveredInsta}"
        @mouseover="isHoveredInsta = true"
        @mouseleave="isHoveredInsta = false" 
        @click="saveInstaQst">
          Save
          <svg :style="{marginInline: '5px'}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#7fadc7" fill="none">
              <path d="M6.375 22V20.7166C6.375 19.6817 6.1378 18.6609 5.68218 17.7352L5.39077 17.1432C4.80497 15.953 4.5 14.6406 4.5 13.3099V8.19048C4.5 7.4015 5.1296 6.76191 5.90625 6.76191C6.6829 6.76191 7.3125 7.4015 7.3125 8.19048M14.8125 22C14.8125 21.5674 14.8117 21.1365 14.8125 20.7212C14.8146 19.5775 16.0083 18.9963 16.9844 18.4266C18.0998 17.7757 18.9943 16.7254 19.4068 15.4608C19.678 14.6293 18.9266 13.9057 18.0293 13.9057C17.132 13.9057 16.3696 14.6903 15.7189 15.291C15.617 15.3851 15.5203 15.4586 15.4424 15.5084C14.1737 16.317 12.4807 15.3439 12.4807 13.9057C12.4807 12.4676 14.1737 11.4945 15.4424 12.3031C15.7584 12.469 16.019 12.6894 16.2273 12.9183C16.6998 13.4375 17.3234 13.9057 18.0364 13.9057C18.9501 13.9057 19.716 13.1694 19.4445 12.3212C18.7666 10.2034 16.766 8.66863 14.4034 8.66863C13.4062 8.66863 12.9375 8.95085 12.9375 8.95085L15.0823 3.94719C15.4571 3.01828 14.7846 2 13.7964 2C13.27 2 12.7888 2.30213 12.5534 2.78042L10.125 7.71429M10.125 7.71429L9.67741 8.62368M10.125 7.71429V4.38095C10.125 3.59197 9.4954 2.95238 8.71875 2.95238C7.9421 2.95238 7.3125 3.59197 7.3125 4.38095V8.19048M7.3125 8.19048V11.5238" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
      </div>
      <div v-else :style="{
        maxWidth: '100%',
        height: '50px',
        display: 'flex',
        justifyContent: 'center',
        marginInline: '5px',
        paddingInline: '10px',
        textAlign: 'center',
        alignItems: 'center',
        
        borderRadius: '5px',
        color: '#7fadc7'
      }" >
      <Loading type="submit" :width="'40px'"/>
      </div>

        <div class="structure-box2">
          <div v-if="errorInputing" class="errorText">
            {{ textErrorInputing }}
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import CheckBox from "../components/CheckBox.vue";
import Loading from '../components/items/loading.vue';

export default {
  name: "InstagramEdit",
  components: {
    CheckBox,
    Loading,
    
  },

  data() {
    return {
      instaUser: localStorage.getItem('instaUser'),
      instaImage: localStorage.getItem('instaLinkHD'),
      isInstaConfirmed: false,
      sQst: '', // Add this line to store input value
      sDescription: '', // Add this line to store input value
      cAnswer: '', // Add this line to store input value
      wAnswer1: '', // Add this line to store input value
      wAnswer2: '', // Add this line to store input value
      isEditing: true,
      dDContent: 'World',
      isDropdownOpen: false,
      errorInputing: false,
      textErrorInputing: 'Pleas make sur ther was no empty Value',
      isChecked: this.value,
      isHovered0: false,
      isHovered1: false,
      isHovered2: false,
      isHovered3: false,
      isHoveredInsta: false,
      isHoveredPro: false,
      isSaving: false,
      
      countries: [
        { name: 'World', flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fd/Flag_of_FOTW.svg/1200px-Flag_of_FOTW.svg.png' },
  { name: 'Afghanistan', flag: 'https://flagpedia.net/data/flags/h80/af.png' },
  { name: 'Albania', flag: 'https://flagpedia.net/data/flags/h80/al.png' },
  { name: 'Algeria', flag: 'https://flagpedia.net/data/flags/h80/dz.png' },
  { name: 'Andorra', flag: 'https://flagpedia.net/data/flags/h80/ad.png' },
  { name: 'Angola', flag: 'https://flagpedia.net/data/flags/h80/ao.png' },
  { name: 'Antigua and Barbuda', flag: 'https://flagpedia.net/data/flags/h80/ag.png' },
  { name: 'Argentina', flag: 'https://flagpedia.net/data/flags/h80/ar.png' },
  { name: 'Armenia', flag: 'https://flagpedia.net/data/flags/h80/am.png' },
  { name: 'Australia', flag: 'https://flagpedia.net/data/flags/h80/au.png' },
  { name: 'Austria', flag: 'https://flagpedia.net/data/flags/h80/at.png' },
  { name: 'Azerbaijan', flag: 'https://flagpedia.net/data/flags/h80/az.png' },
  { name: 'Bahamas', flag: 'https://flagpedia.net/data/flags/h80/bs.png' },
  { name: 'Bahrain', flag: 'https://flagpedia.net/data/flags/h80/bh.png' },
  { name: 'Bangladesh', flag: 'https://flagpedia.net/data/flags/h80/bd.png' },
  { name: 'Barbados', flag: 'https://flagpedia.net/data/flags/h80/bb.png' },
  { name: 'Belarus', flag: 'https://flagpedia.net/data/flags/h80/by.png' },
  { name: 'Belgium', flag: 'https://flagpedia.net/data/flags/h80/be.png' },
  { name: 'Belize', flag: 'https://flagpedia.net/data/flags/h80/bz.png' },
  { name: 'Benin', flag: 'https://flagpedia.net/data/flags/h80/bj.png' },
  { name: 'Bhutan', flag: 'https://flagpedia.net/data/flags/h80/bt.png' },
  { name: 'Bolivia', flag: 'https://flagpedia.net/data/flags/h80/bo.png' },
  { name: 'Bosnia and Herzegovina', flag: 'https://flagpedia.net/data/flags/h80/ba.png' },
  { name: 'Botswana', flag: 'https://flagpedia.net/data/flags/h80/bw.png' },
  { name: 'Brazil', flag: 'https://flagpedia.net/data/flags/h80/br.png' },
  { name: 'Brunei', flag: 'https://flagpedia.net/data/flags/h80/bn.png' },
  { name: 'Bulgaria', flag: 'https://flagpedia.net/data/flags/h80/bg.png' },
  { name: 'Burkina Faso', flag: 'https://flagpedia.net/data/flags/h80/bf.png' },
  { name: 'Burundi', flag: 'https://flagpedia.net/data/flags/h80/bi.png' },
  { name: 'Cabo Verde', flag: 'https://flagpedia.net/data/flags/h80/cv.png' },
  { name: 'Cambodia', flag: 'https://flagpedia.net/data/flags/h80/kh.png' },
  { name: 'Cameroon', flag: 'https://flagpedia.net/data/flags/h80/cm.png' },
  { name: 'Canada', flag: 'https://flagpedia.net/data/flags/h80/ca.png' },
  { name: 'Central African Republic', flag: 'https://flagpedia.net/data/flags/h80/cf.png' },
  { name: 'Chad', flag: 'https://flagpedia.net/data/flags/h80/td.png' },
  { name: 'Chile', flag: 'https://flagpedia.net/data/flags/h80/cl.png' },
  { name: 'China', flag: 'https://flagpedia.net/data/flags/h80/cn.png' },
  { name: 'Colombia', flag: 'https://flagpedia.net/data/flags/h80/co.png' },
  { name: 'Comoros', flag: 'https://flagpedia.net/data/flags/h80/km.png' },
  { name: 'Congo (Congo-Brazzaville)', flag: 'https://flagpedia.net/data/flags/h80/cg.png' },
  { name: 'Costa Rica', flag: 'https://flagpedia.net/data/flags/h80/cr.png' },
  { name: 'Croatia', flag: 'https://flagpedia.net/data/flags/h80/hr.png' },
  { name: 'Cuba', flag: 'https://flagpedia.net/data/flags/h80/cu.png' },
  { name: 'Cyprus', flag: 'https://flagpedia.net/data/flags/h80/cy.png' },
  { name: 'Czechia', flag: 'https://flagpedia.net/data/flags/h80/cz.png' },
  { name: 'Democratic Republic of the Congo', flag: 'https://flagpedia.net/data/flags/h80/cd.png' },
  { name: 'Denmark', flag: 'https://flagpedia.net/data/flags/h80/dk.png' },
  { name: 'Djibouti', flag: 'https://flagpedia.net/data/flags/h80/dj.png' },
  { name: 'Dominica', flag: 'https://flagpedia.net/data/flags/h80/dm.png' },
  { name: 'Dominican Republic', flag: 'https://flagpedia.net/data/flags/h80/do.png' },
  { name: 'Ecuador', flag: 'https://flagpedia.net/data/flags/h80/ec.png' },
  { name: 'Egypt', flag: 'https://flagpedia.net/data/flags/h80/eg.png' },
  { name: 'El Salvador', flag: 'https://flagpedia.net/data/flags/h80/sv.png' },
  { name: 'Equatorial Guinea', flag: 'https://flagpedia.net/data/flags/h80/gq.png' },
  { name: 'Eritrea', flag: 'https://flagpedia.net/data/flags/h80/er.png' },
  { name: 'Estonia', flag: 'https://flagpedia.net/data/flags/h80/ee.png' },
  { name: 'Eswatini', flag: 'https://flagpedia.net/data/flags/h80/sz.png' },
  { name: 'Ethiopia', flag: 'https://flagpedia.net/data/flags/h80/et.png' },
  { name: 'Fiji', flag: 'https://flagpedia.net/data/flags/h80/fj.png' },
  { name: 'Finland', flag: 'https://flagpedia.net/data/flags/h80/fi.png' },
  { name: 'France', flag: 'https://flagpedia.net/data/flags/h80/fr.png' },
  { name: 'Gabon', flag: 'https://flagpedia.net/data/flags/h80/ga.png' },
  { name: 'Gambia', flag: 'https://flagpedia.net/data/flags/h80/gm.png' },
  { name: 'Georgia', flag: 'https://flagpedia.net/data/flags/h80/ge.png' },
  { name: 'Germany', flag: 'https://flagpedia.net/data/flags/h80/de.png' },
  { name: 'Ghana', flag: 'https://flagpedia.net/data/flags/h80/gh.png' },
  { name: 'Greece', flag: 'https://flagpedia.net/data/flags/h80/gr.png' },
  { name: 'Grenada', flag: 'https://flagpedia.net/data/flags/h80/gd.png' },
  { name: 'Guatemala', flag: 'https://flagpedia.net/data/flags/h80/gt.png' },
  { name: 'Guinea', flag: 'https://flagpedia.net/data/flags/h80/gn.png' },
  { name: 'Guinea-Bissau', flag: 'https://flagpedia.net/data/flags/h80/gw.png' },
  { name: 'Guyana', flag: 'https://flagpedia.net/data/flags/h80/gy.png' },
  { name: 'Haiti', flag: 'https://flagpedia.net/data/flags/h80/ht.png' },
  { name: 'Honduras', flag: 'https://flagpedia.net/data/flags/h80/hn.png' },
  { name: 'Hungary', flag: 'https://flagpedia.net/data/flags/h80/hu.png' },
  { name: 'Iceland', flag: 'https://flagpedia.net/data/flags/h80/is.png' },
  { name: 'India', flag: 'https://flagpedia.net/data/flags/h80/in.png' },
  { name: 'Indonesia', flag: 'https://flagpedia.net/data/flags/h80/id.png' },
  { name: 'Iran', flag: 'https://flagpedia.net/data/flags/h80/ir.png' },
  { name: 'Iraq', flag: 'https://flagpedia.net/data/flags/h80/iq.png' },
  { name: 'Ireland', flag: 'https://flagpedia.net/data/flags/h80/ie.png' },
  { name: 'Israel', flag: 'https://flagpedia.net/data/flags/h80/il.png' },
  { name: 'Italy', flag: 'https://flagpedia.net/data/flags/h80/it.png' },
  { name: 'Jamaica', flag: 'https://flagpedia.net/data/flags/h80/jm.png' },
  { name: 'Japan', flag: 'https://flagpedia.net/data/flags/h80/jp.png' },
  { name: 'Jordan', flag: 'https://flagpedia.net/data/flags/h80/jo.png' },
  { name: 'Kazakhstan', flag: 'https://flagpedia.net/data/flags/h80/kz.png' },
  { name: 'Kenya', flag: 'https://flagpedia.net/data/flags/h80/ke.png' },
  { name: 'Kiribati', flag: 'https://flagpedia.net/data/flags/h80/ki.png' },
  { name: 'Kuwait', flag: 'https://flagpedia.net/data/flags/h80/kw.png' },
  { name: 'Kyrgyzstan', flag: 'https://flagpedia.net/data/flags/h80/kg.png' },
  { name: 'Laos', flag: 'https://flagpedia.net/data/flags/h80/la.png' },
  { name: 'Latvia', flag: 'https://flagpedia.net/data/flags/h80/lv.png' },
  { name: 'Lebanon', flag: 'https://flagpedia.net/data/flags/h80/lb.png' },
  { name: 'Lesotho', flag: 'https://flagpedia.net/data/flags/h80/ls.png' },
  { name: 'Liberia', flag: 'https://flagpedia.net/data/flags/h80/lr.png' },
  { name: 'Libya', flag: 'https://flagpedia.net/data/flags/h80/ly.png' },
  { name: 'Liechtenstein', flag: 'https://flagpedia.net/data/flags/h80/li.png' },
  { name: 'Lithuania', flag: 'https://flagpedia.net/data/flags/h80/lt.png' },
  { name: 'Luxembourg', flag: 'https://flagpedia.net/data/flags/h80/lu.png' },
  { name: 'Madagascar', flag: 'https://flagpedia.net/data/flags/h80/mg.png' },
  { name: 'Malawi', flag: 'https://flagpedia.net/data/flags/h80/mw.png' },
  { name: 'Malaysia', flag: 'https://flagpedia.net/data/flags/h80/my.png' },
  { name: 'Maldives', flag: 'https://flagpedia.net/data/flags/h80/mv.png' },
  { name: 'Mali', flag: 'https://flagpedia.net/data/flags/h80/ml.png' },
  { name: 'Malta', flag: 'https://flagpedia.net/data/flags/h80/mt.png' },
  { name: 'Marshall Islands', flag: 'https://flagpedia.net/data/flags/h80/mh.png' },
  { name: 'Mauritania', flag: 'https://flagpedia.net/data/flags/h80/mr.png' },
  { name: 'Mauritius', flag: 'https://flagpedia.net/data/flags/h80/mu.png' },
  { name: 'Mexico', flag: 'https://flagpedia.net/data/flags/h80/mx.png' },
  { name: 'Micronesia', flag: 'https://flagpedia.net/data/flags/h80/fm.png' },
  { name: 'Moldova', flag: 'https://flagpedia.net/data/flags/h80/md.png' },
  { name: 'Monaco', flag: 'https://flagpedia.net/data/flags/h80/mc.png' },
  { name: 'Mongolia', flag: 'https://flagpedia.net/data/flags/h80/mn.png' },
  { name: 'Montenegro', flag: 'https://flagpedia.net/data/flags/h80/me.png' },
  { name: 'Morocco', flag: 'https://flagpedia.net/data/flags/h80/ma.png' },
  { name: 'Mozambique', flag: 'https://flagpedia.net/data/flags/h80/mz.png' },
  { name: 'Myanmar', flag: 'https://flagpedia.net/data/flags/h80/mm.png' },
  { name: 'Namibia', flag: 'https://flagpedia.net/data/flags/h80/na.png' },
  { name: 'Nauru', flag: 'https://flagpedia.net/data/flags/h80/nr.png' },
  { name: 'Nepal', flag: 'https://flagpedia.net/data/flags/h80/np.png' },
  { name: 'Netherlands', flag: 'https://flagpedia.net/data/flags/h80/nl.png' },
  { name: 'New Zealand', flag: 'https://flagpedia.net/data/flags/h80/nz.png' },
  { name: 'Nicaragua', flag: 'https://flagpedia.net/data/flags/h80/ni.png' },
  { name: 'Niger', flag: 'https://flagpedia.net/data/flags/h80/ne.png' },
  { name: 'Nigeria', flag: 'https://flagpedia.net/data/flags/h80/ng.png' },
  { name: 'North Korea', flag: 'https://flagpedia.net/data/flags/h80/kp.png' },
  { name: 'North Macedonia', flag: 'https://flagpedia.net/data/flags/h80/mk.png' },
  { name: 'Norway', flag: 'https://flagpedia.net/data/flags/h80/no.png' },
  { name: 'Oman', flag: 'https://flagpedia.net/data/flags/h80/om.png' },
  { name: 'Pakistan', flag: 'https://flagpedia.net/data/flags/h80/pk.png' },
  { name: 'Palau', flag: 'https://flagpedia.net/data/flags/h80/pw.png' },
  { name: 'Palestine', flag: 'https://flagpedia.net/data/flags/h80/ps.png' },
  { name: 'Panama', flag: 'https://flagpedia.net/data/flags/h80/pa.png' },
  { name: 'Papua New Guinea', flag: 'https://flagpedia.net/data/flags/h80/pg.png' },
  { name: 'Paraguay', flag: 'https://flagpedia.net/data/flags/h80/py.png' },
  { name: 'Peru', flag: 'https://flagpedia.net/data/flags/h80/pe.png' },
  { name: 'Philippines', flag: 'https://flagpedia.net/data/flags/h80/ph.png' },
  { name: 'Poland', flag: 'https://flagpedia.net/data/flags/h80/pl.png' },
  { name: 'Portugal', flag: 'https://flagpedia.net/data/flags/h80/pt.png' },
  { name: 'Qatar', flag: 'https://flagpedia.net/data/flags/h80/qa.png' },
  { name: 'Romania', flag: 'https://flagpedia.net/data/flags/h80/ro.png' },
  { name: 'Russia', flag: 'https://flagpedia.net/data/flags/h80/ru.png' },
  { name: 'Rwanda', flag: 'https://flagpedia.net/data/flags/h80/rw.png' },
  { name: 'Saint Kitts and Nevis', flag: 'https://flagpedia.net/data/flags/h80/kn.png' },
  { name: 'Saint Lucia', flag: 'https://flagpedia.net/data/flags/h80/lc.png' },
  { name: 'Saint Vincent and the Grenadines', flag: 'https://flagpedia.net/data/flags/h80/vc.png' },
  { name: 'Samoa', flag: 'https://flagpedia.net/data/flags/h80/ws.png' },
  { name: 'San Marino', flag: 'https://flagpedia.net/data/flags/h80/sm.png' },
  { name: 'Sao Tome and Principe', flag: 'https://flagpedia.net/data/flags/h80/st.png' },
  { name: 'Saudi Arabia', flag: 'https://flagpedia.net/data/flags/h80/sa.png' },
  { name: 'Senegal', flag: 'https://flagpedia.net/data/flags/h80/sn.png' },
  { name: 'Serbia', flag: 'https://flagpedia.net/data/flags/h80/rs.png' },
      { name: 'Seychelles', flag: 'https://flagpedia.net/data/flags/h80/sc.png' },
  { name: 'Sierra Leone', flag: 'https://flagpedia.net/data/flags/h80/sl.png' },
  { name: 'Singapore', flag: 'https://flagpedia.net/data/flags/h80/sg.png' },
  { name: 'Slovakia', flag: 'https://flagpedia.net/data/flags/h80/sk.png' },
  { name: 'Slovenia', flag: 'https://flagpedia.net/data/flags/h80/si.png' },
  { name: 'Solomon Islands', flag: 'https://flagpedia.net/data/flags/h80/sb.png' },
  { name: 'Somalia', flag: 'https://flagpedia.net/data/flags/h80/so.png' },
  { name: 'South Africa', flag: 'https://flagpedia.net/data/flags/h80/za.png' },
  { name: 'South Korea', flag: 'https://flagpedia.net/data/flags/h80/kr.png' },
  { name: 'South Sudan', flag: 'https://flagpedia.net/data/flags/h80/ss.png' },
  { name: 'Spain', flag: 'https://flagpedia.net/data/flags/h80/es.png' },
  { name: 'Sri Lanka', flag: 'https://flagpedia.net/data/flags/h80/lk.png' },
  { name: 'Sudan', flag: 'https://flagpedia.net/data/flags/h80/sd.png' },
  { name: 'Suriname', flag: 'https://flagpedia.net/data/flags/h80/sr.png' },
  { name: 'Sweden', flag: 'https://flagpedia.net/data/flags/h80/se.png' },
  { name: 'Switzerland', flag: 'https://flagpedia.net/data/flags/h80/ch.png' },
  { name: 'Syria', flag: 'https://flagpedia.net/data/flags/h80/sy.png' },
  { name: 'Taiwan', flag: 'https://flagpedia.net/data/flags/h80/tw.png' },
  { name: 'Tajikistan', flag: 'https://flagpedia.net/data/flags/h80/tj.png' },
  { name: 'Tanzania', flag: 'https://flagpedia.net/data/flags/h80/tz.png' },
  { name: 'Thailand', flag: 'https://flagpedia.net/data/flags/h80/th.png' },
  { name: 'Togo', flag: 'https://flagpedia.net/data/flags/h80/tg.png' },
  { name: 'Tonga', flag: 'https://flagpedia.net/data/flags/h80/to.png' },
  { name: 'Trinidad and Tobago', flag: 'https://flagpedia.net/data/flags/h80/tt.png' },
  { name: 'Tunisia', flag: 'https://flagpedia.net/data/flags/h80/tn.png' },
  { name: 'Turkey', flag: 'https://flagpedia.net/data/flags/h80/tr.png' },
  { name: 'Turkmenistan', flag: 'https://flagpedia.net/data/flags/h80/tm.png' },
  { name: 'Tuvalu', flag: 'https://flagpedia.net/data/flags/h80/tv.png' },
  { name: 'Uganda', flag: 'https://flagpedia.net/data/flags/h80/ug.png' },
  { name: 'Ukraine', flag: 'https://flagpedia.net/data/flags/h80/ua.png' },
  { name: 'United Arab Emirates', flag: 'https://flagpedia.net/data/flags/h80/ae.png' },
  { name: 'United Kingdom', flag: 'https://flagpedia.net/data/flags/h80/gb.png' },
  { name: 'United States', flag: 'https://flagpedia.net/data/flags/h80/us.png' },
  { name: 'Uruguay', flag: 'https://flagpedia.net/data/flags/h80/uy.png' },
  { name: 'Uzbekistan', flag: 'https://flagpedia.net/data/flags/h80/uz.png' },
  { name: 'Vanuatu', flag: 'https://flagpedia.net/data/flags/h80/vu.png' },
  { name: 'Vatican City', flag: 'https://flagpedia.net/data/flags/h80/va.png' },
  { name: 'Venezuela', flag: 'https://flagpedia.net/data/flags/h80/ve.png' },
  { name: 'Vietnam', flag: 'https://flagpedia.net/data/flags/h80/vn.png' },
  { name: 'Yemen', flag: 'https://flagpedia.net/data/flags/h80/ye.png' },
  { name: 'Zambia', flag: 'https://flagpedia.net/data/flags/h80/zm.png' },
  { name: 'Zimbabwe', flag: 'https://flagpedia.net/data/flags/h80/zw.png' }
],
    };
  },

  async created(){
    await this.getInstaQst();
  },


  methods: {

    viewCard(){
      this.$emit('instagram-card');
    },

    viewStat(){
      this.$emit('instagram-stat');
    },

    instaChange(){
      this.$emit('instagram-change');
    },

    proxyImage(url) {
      return `https://swingi.pro/api.php?action=proxyImage&url=${encodeURIComponent(url)}`;
    },

    async saveInstaQst() {
      this.isSaving = true;
      this.textErrorInputing = '';
  // Check if all required inputs are provided
  if (this.sQst && this.wAnswer2 && this.sDescription && this.cAnswer && this.wAnswer1) {
    try {
      // Call the method to add the Instagram question
      await this.addInstaQst();
    } catch (error) {
      // Handle errors from addInstaQst
      this.errorInputing = true;
      this.isSaving = false;
      this.textErrorInputing = 'Error while saving Instagram question: ', error;
    }
  } else {
    this.errorInputing = true;
    this.isSaving = false;
    this.textErrorInputing = 'Check if all required inputs are provided';
  }
},

async getInstaQst() {
  try {

    var token = localStorage.getItem('authToken');
    
    const response = await fetch(`https://swingi.pro/api.php?action=getMediaQst&token=${encodeURIComponent(token)}&tableName=instaQst`, {
      method: 'GET', // Utilise GET puisque tu passes les données dans l'URL
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();

    if (data.error) {
      console.error('Erreur:', data.error, 'Détails:', data.details);
    } else {
      
      if (data.successe === false){
        this.$emit('insta-active', false);
        localStorage.setItem('insta-Active', false);
      } else {
        if (data.data) {
          localStorage.setItem('insta-Active', true);
          var instaQst = data.data;
          this.sQst = instaQst[2];
          this.sDescription = instaQst[1];
          this.cAnswer = instaQst[3];
          this.wAnswer1 = instaQst[4];
          this.wAnswer2 = instaQst[5];
          this.dDContent = instaQst[6];
          if (instaQst[7] == '0'){
            this.$emit('insta-active', false);
            this.isChecked = false;
          } else if (instaQst[7] == '1') {
            this.$emit('insta-active', true);
            this.isChecked = true;
          }
        } else {
          this.$emit('insta-active', false);
          localStorage.setItem('insta-Active', false);
        }


          console.log('this is instaQst:', this.isChecked);
        
      }
    }


  } catch (error) {
    console.error('Erreur lors de la requête POST:', error);
    this.info = { error: `Error: ${error.message}` };
  }
},

async cancel() {
  this.isInstaEdit = false;
      this.$emit('cancel', false);
},

isAccepted(value) {
      
      this.isChecked = !value;

    },

    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen; // Toggle dropdown visibility
    },
    updateContent(value) {
      this.dDContent = value; // Update the dropdown content
      this.isDropdownOpen = false; // Close the dropdown after selection
    },

    changeInsta() {
      this.isInstaEdit = false;
      this.isInstaChange = true;
      this.$emit('instagram-change');
    },

    autoResizeInput() {
      const input = this.$refs.autoResizeInput;
      input.style.height = 'auto';
      input.style.height = (input.scrollHeight) + 'px';
    },

    async addInstaQst() {
      var value = 0;
      if (this.isChecked === false) {
        value = 0;
        localStorage.setItem('insta-Active', false);
      } else {
        value = 1;
        localStorage.setItem('insta-Active', true);
      }
  const userDetails = {
    token: localStorage.getItem('authToken'),
    dscr: this.sDescription,
    qst: this.sQst,
    answer1: this.cAnswer,
    answer2: this.wAnswer1,
    answer3: this.wAnswer2,
    country: this.dDContent,
    activ: value,
    dayTime: new Date().toISOString(),
    tableName: 'instaQst'
  };

  this.textErrorInputing = 'Saving Data';

  try {
    var response = await fetch('https://swingi.pro/api.php?action=saveMediaQst', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(userDetails)
    });

    

    if (!response.ok) {
      this.errorInputing = true;
      this.isSaving = false;
      this.textErrorInputing = 'Network response was not ok';
      throw new Error('Network response was not ok');
    }

    const data = await response.json();

    if (!data.success) {
      this.errorInputing = true;
      this.isSaving = false;
      this.textErrorInputing = data.message;
    } else {
      this.errorInputing = true;
      this.isSaving = false;
      this.textErrorInputing = 'Data Saved Correctly';
    }

    this.canGo = true;
  } catch (error) {
    this.errorInputing = true;
    this.isSaving = false;
    this.textErrorInputing = 'Error in posting request: ', error;
    this.info = { error: `Error: ${error.message}` };
  }
}
  },

  mounted() {
    this.$nextTick(() => {
      const input = this.$refs.autoResizeInput;
      if (input) {
        input.setAttribute('style', 'height:' + (input.scrollHeight) + 'px;overflow-y:hidden;');
      }
    });
  }
}
</script>

<style>

text{
  width: 100%;

}

input[type="wrong-answer"] {
  margin: 5px;
  width: calc(100% - 10px);
  height: 40px;
  min-height: 40px;
  padding: 10px 15px;
  font-size: 16px;
  border: 2px solid rgb(255, 217, 217);
  border-radius: 5px;
  background-color: white;
  transition: all 0.3s ease;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  resize: vertical;
  overflow: hidden;
  font-family: 'Josefin Sans', sans-serif;
}

input[type="wrong-answer"]:focus {
  border-color: #3498db;
  background-color: white;
  box-shadow: 0 4px 8px rgba(52, 152, 219, 0.2);
  font-family: 'Josefin Sans', sans-serif;
  padding-left: 5px;
}

input[type="wrong-answer"]::placeholder {
  color: white;
}

input[type="correct-answer"] {
  margin: 5px;
  width: calc(100% - 10px);
  height: 40px;
  min-height: 40px;
  padding: 10px 15px;
  font-size: 16px;
  border: 2px solid rgb(212, 240, 206);
  border-radius: 8px;
  background-color: white;
  transition: all 0.3s ease;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  resize: vertical;
  overflow: hidden;
  font-family: 'Josefin Sans', sans-serif;
}

input[type="correct-answer"]:focus {
  border-color: #3498db;
  background-color: white;
  box-shadow: 0 4px 8px rgba(52, 152, 219, 0.2);
  font-family: 'Josefin Sans', sans-serif;
  padding-left: 5px;
}

input[type="correct-answer"]::placeholder {
  color: white;
}

input[type="text"] {
  margin: 5px;
  width: calc(100% - 10px);
  height: 40px;
  min-height: 40px;
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
  transition: all 0.3s ease;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  resize: vertical;
  overflow: hidden;
  font-family: 'Josefin Sans', sans-serif;
}

input[type="text"]:focus {
  border-color: #3498db;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(52, 152, 219, 0.2);
  font-family: 'Josefin Sans', sans-serif;
  padding-left: 5px;
}

input[type="text"]::placeholder {
  color: #aaaaaa;
}

/* Basic styling for the dropdown */
.dropdown {
  margin: 5px;
  width: calc(100% - 10px);
  position: relative;
  display: inline-block;
}

.dropbtn {
  margin: 5px;
  width: calc(100% - 10px);
  height: 30px;
  background-color: #3498db; /* Blue background */
  color: white; /* White text */
  padding: 10px 20px; /* Padding for the button */
  font-size: 16px; /* Font size */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth background transition */
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Josefin Sans', sans-serif;
}

.dropbtn:hover {
  background-color: #2980b9; /* Darker blue on hover */
}

.dropdown-content {
  margin: 5px;
  width: calc(100% - 10px);
  display: none; /* Hidden by default */
  position: absolute; /* Position it below the button */
  background-color: rgba(255, 255, 255, 0.8);
  border: 2px solid rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(20px);
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  border-radius: 10px;
  min-width: 200px; /* Minimum width */
  max-height: 300px; /* Set a maximum height */
  overflow-y: auto; /* Enable vertical scrolling */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); /* Shadow effect */
  z-index: 1; /* Ensure it appears above other elements */
  border-radius: 5px; /* Rounded corners */
}

.dropdown-content a {
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center items vertically */
  color: black; /* Text color */
  padding: 12px 16px; /* Padding for links */
  text-decoration: none; /* No underline */
  font-family: 'Josefin Sans', sans-serif;
  transition: background-color 0.3s; /* Smooth background transition */
  cursor: pointer;
}

.dropdown-content a:hover {
  background-color: #f1f1f1; /* Light gray on hover */
}

/* Show the dropdown content when the button is clicked */
.dropdown-content {
  display: block; /* Show the dropdown */
}

.flag-icon {
  width: 20px; /* Width of the flag icon */
  height: auto; /* Maintain aspect ratio */
  margin-right: 10px; /* Space between flag and text */
}


.body3 {
  max-width: 500px;
    width: 90%; /* Prendre toute la largeur du parent */
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(255, 255, 255, .8);
    box-shadow: 0 0 10px #7fadc760;
    

  }

  .dscr {
    margin: 5px;
    width: 100%;
    font-family: 'Josefin Sans', sans-serif;
  }

  .structure-box {
    margin-top: 30px;
  }

  .structure-box:hover {
    background-color: rgba(95, 127, 255, 0.1);
    backdrop-filter: blur(2px);
    margin-top: 30px;
  }

  .structure-box2 {
    height: 50px;
    display: flex;
    justify-content: center;
  }

    .short-text {
    margin: 2px;
    width: 100%;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 14px;
    color: gray;
  }

  .errorText {
    width: 100%;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 14px;
    color: #ff5555;
    display: flex;
    justify-content: center;
  }

  .res-1 {
    margin: 5px;
    width: calc(100% - 10px);
    height: 25px;
    background-color: #ffffff60;
    font-family: 'Josefin Sans', sans-serif;
  }

  .instaTitle {
    min-width: 70px;
    margin: 5px;
    font-size: 14px; /* Ajuster la taille de la police selon vos besoins */
    font-weight: bold;
    background-image: linear-gradient(90deg, #f09433, #e6683c, #dc2743, #cc2366, #bc1888);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .userTitle {
    display: flex; /* Définit un conteneur flex */
    align-items: center; /* Centre le texte verticalement */
    justify-content: center; /* Centre le texte horizontalement */
    text-align: center; /* Centrer le texte horizontalement à l'intérieur de l'élément */
    font-size: 14px; /* Ajuster la taille de la police selon vos besoins */
    height: 100%; /* Assurez-vous que le conteneur a une hauteur définie */
    font-family: 'Playwrite CU', sans-serif;
    font-weight: bold;

  }


  .profileImage{
    height: 40px;
    width: 40px;
    min-width: 40px;
    border-radius: 50%; /* Rend l'image circulaire */
  }

  .viewStatBox {
    margin: 5px;
    height: 20px;

  }

  .instaImage{
    margin-right: 5px;
    height: 40px;
    width: 40px;

  }
  .instaName{
    margin-right: 5px;
    height: 40px;
    width: 90%;

  }

  .instaBox {
    margin: 10px;
    height: 75px;
    width: 50%;
    background-color: #cbecff;
  }



  .text3 {
    width: 60%;
    font-size: 10px; /* Ajuster la taille de la police selon vos besoins */
    color: black; /* Ajuster la couleur du texte selon vos besoins */
  }




  .boxBtn3 {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;

  }
  .btn3 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 30px; /* Ajuster la hauteur selon vos besoins */
    background-color: #00A3FF80; /* Couleur de fond */
    color: black; /* Couleur du texte */
    border: none; /* Enlever les bordures par défaut */
    border-radius: 2px; /* Bordure arrondie */
    cursor: pointer; /* Curseur pointeur */
    text-align: center; /* Centrer le texte */
    font-size: 14px; /* Ajuster la taille de la police selon vos besoins */

  }
  
  .btn3:hover {
    background-color: #00A3FF; /* Couleur de fond lors du survol */
  }


</style>