<template>
    
    <div :style="{
        marginLeft: '5%',
        width: '90%',
        minWidth: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        marginTop: '100px',
        flexDirection: 'column',
        borderRadius: '6px',
        background: '#ffffffcc',
        border: '1px solid rgba(255, 255, 255, .8)',
        boxShadow: '0 0 10px #7fadc760'

    }">
    <div :style="{
        width: '100%',
        alignItems: 'center',
    }">
    <div v-if="existing[0]" :style="{display: 'flex', justifyContent: 'center', alignItems: 'center'}">

        <MediaBanner :bannerInfo="{name: name[0], image: image[0], type: 'insta', isMobile: isMobile, check: isClicked0}" @input="getEmiter" @clicked="clicked"/>


    </div>

    <div v-if="existing[1]" :style="{display: 'flex', justifyContent: 'center', alignItems: 'center'}">

        <MediaBanner :bannerInfo="{name: name[1], image: image[1], type: 'youtube', isMobile: isMobile, check: isClicked1}" @input="getEmiter" @clicked="clicked"/>

    </div>

    <div v-if="existing[2]" :style="{display: 'flex', justifyContent: 'center', alignItems: 'center'}">

        <MediaBanner :bannerInfo="{name: name[2], image: image[2], type: 'tiktok', isMobile: isMobile, check: isClicked2}" @input="getEmiter" @clicked="clicked"/>


    </div>

    </div>
    

        
    <div v-if="existing[count]" >
        <div :style="{
            width: '90%',
            minWidth: '300px',
            height: '20px',
            marginTop: '20px',
            display: 'flex',
            alignItems: 'left',
            fontFamily: 'Josefin Sans, sans-serif', 
            fontWeight: 'bold', 
            fontSize: '10px',
            color: 'gray',
            

        }">
            discription :
        </div>

        <div :style="{
            width: '90%',
            minWidth: '300px',
            height: '30px',
            display: 'flex',
            alignItems: 'left',
            fontFamily: 'Josefin Sans, sans-serif', 
            fontWeight: 'bold', 
            fontSize: '14px'

        }">
            {{ discription[count] }}.
        </div>

        <div :style="{
            width: '90%',
            minWidth: '300px',
            height: '20px',
            marginTop: '20px',
            display: 'flex',
            alignItems: 'left',
            fontFamily: 'Josefin Sans, sans-serif', 
            fontWeight: 'bold', 
            fontSize: '10px',
            color: 'gray',

        }">
            Question :
        </div>

        <div :style="{
            width: '90%',
            minWidth: '300px',
            height: '30px',
            display: 'flex',
            alignItems: 'left',
            fontFamily: 'Josefin Sans, sans-serif', 
            fontWeight: 'bold', 
            fontSize: '14px'

        }">
            {{ qst[count] }} ?
        </div>

        <div :style="{
            width: '90%',
            minWidth: '300px',
            height: '20px',
            marginTop: '20px',
            display: 'flex',
            alignItems: 'left',
            fontFamily: 'Josefin Sans, sans-serif', 
            fontWeight: 'bold', 
            fontSize: '10px',
            color: 'gray',

        }">
            Answer 1 :
        </div>

        <div :style="{
            width: '90%',
            minWidth: '300px',
            height: '50px',
            display: 'flex',
            alignItems: 'left',
            fontFamily: 'Josefin Sans, sans-serif', 
            fontWeight: 'bold', 
            fontSize: '14px',
            cursor: 'pointer',

        }" class="hoverable" @click="validateQst(answer1)">
            {{ answer1[count] }}.
        </div>

        <div :style="{
            width: '90%',
            minWidth: '300px',
            height: '20px',
            marginTop: '20px',
            display: 'flex',
            alignItems: 'left',
            fontFamily: 'Josefin Sans, sans-serif', 
            fontWeight: 'bold', 
            fontSize: '10px',
            color: 'gray',

        }">
            Answer 2 :
        </div>

        <div :style="{
            width: '90%',
            minWidth: '300px',
            height: '50px',
            display: 'flex',
            alignItems: 'left',
            fontFamily: 'Josefin Sans, sans-serif', 
            fontWeight: 'bold', 
            fontSize: '14px',
            cursor: 'pointer',

        }" class="hoverable" @click="validateQst(answer2)">
            {{ answer2[count] }}.
        </div>

        <div :style="{
            width: '90%',
            minWidth: '300px',
            height: '20px',
            marginTop: '20px',
            display: 'flex',
            alignItems: 'left',
            fontFamily: 'Josefin Sans, sans-serif', 
            fontWeight: 'bold', 
            fontSize: '10px',
            color: 'gray',

        }">
            Answer 3 :
        </div>

        <div :style="{
            width: '90%',
            minWidth: '300px',
            height: '50px',
            display: 'flex',
            alignItems: 'left',
            fontFamily: 'Josefin Sans, sans-serif', 
            fontWeight: 'bold', 
            fontSize: '14px',
            cursor: 'pointer',
        }" class="hoverable" @click="validateQst(answer3)">
            {{ answer3[count] }}.
        </div>

        

    </div>
    </div>
        

    <div v-if="isFinished" class="back-box">
        <div class="info-box">
            <div :style="{
                width: '100px',
                marginTop: '10px',
                minWidth: '100px',
                height: '100px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                fontFamily: 'Josefin Sans, sans-serif', 
                fontWeight: 'bold', 
                fontSize: '14px',
            }">
                {{ finishingMsg }}
            </div>

            <div :style="{
                width: '180px',
                marginTop: '10px',
                minWidth: '180px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: 'Josefin Sans, sans-serif', 
                fontWeight: 'bold', 
                fontSize: '14px',
                cursor: 'pointer',
            }" class="hoverable2" @click="isFinished = false">
                OK
            </div>

          
        </div>
      </div>

</template>



<script>

import MediaBanner from '../components/module/mediaBanner.vue';


export default {
    metaInfo: {
    title: 'Instagrame Qst',
    
    meta: [
      { name: 'description', content: 'Answer Qst and earn money when you win' },
      { name: 'keywords', content: 'vue, SEO, web development, earn, money, gagner, argent, creator, createurs, contents, contenue, algorithme, answer' }
    ]
  },
    name: "InstaQst",
    components: {
        MediaBanner
    },
    data () {
        return {
            token: '',
            existing: [],
            image: [],
            name: [],
            swingiPoint: 0,
            qst: [],
            answer1: [],
            answer2: [],
            answer3: [],
            discription: [],
            country: [],
            active: [],
            count: -1,
            isFinished: false,
            finishingMsg: '',
            isManaging: false,
            isMobile: false,
            whatQst: '',
            isClicked0: false,
            isClicked1: false,
            isClicked2: false,

        }
    },

    mounted() {
        this.checkScreenSize();
    
        window.addEventListener('resize', this.checkScreenSize);

        this.token = this.$route.params.id;  // Récupérer le paramètre de l'URL
        this.getQst('insta', 0);
        this.getQst('youtube', 1);
        this.getQst('tiktok', 2);

    },

    beforeUnmount() {
        window.removeEventListener('resize', this.checkScreenSize);
    },

    methods: {
        clicked(value) {
            if(value === 'insta') {
                this.isClicked0 = !this.isClicked0;
                this.isClicked2 = false;
                this.isClicked1 = false;
                this.count = 0;
            } else if (value === 'youtube') {
                this.isClicked1 = !this.isClicked1;
                this.isClicked2 = false;
                this.isClicked0 = false;
                this.count = 1;
            } else if (value === 'tiktok') {
                this.isClicked2 = !this.isClicked2;
                this.isClicked0 = false;
                this.isClicked1 = false;
                this.count = 2;
            }
        },
        getEmiter(value) {
            if(value === 'insta') {
                this.whatQst = 'insta';
            } else if (value === 'youtube') {
                this.whatQst = 'youtube';
            } else if (value === 'tiktok') {
                this.whatQst = 'tiktok';
            }
        },
        checkScreenSize() {
            this.isMobile = window.innerWidth <= 768; // Détecte si l'écran est inférieur ou égal à 768px (taille mobile)
        },
        async validateQst(answer, type) {
            this.isManaging = true;
            const userDetails = {
                token: localStorage.getItem('authToken'),
                tokenJob: this.token,
                answer: answer,
                type: type,
            }
            try {
                const response = await fetch('https://swingi.pro/api.php?action=jobManager', {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(userDetails)
                })

                if (response.ok) {
                    const data = await response.json();
                    
                    this.finishingMsg = data.message;
                    this.isFinished = true;
                    
                    console.log('Response Post: ',data.message);
                } else {
                    const data = await response.json();
                    
                    this.finishingMsg = data.message;
                    this.isFinished = true;
                    console.error('error: ',data.message);
                }
            } catch (e) {
                console.error('error fetch: ',e);
            }
                
            this.isManaging = false;
            
        },
        async getQst(value, init) {
            var tableQst;
            var tableUser;
            if (value === 'insta') {
                tableQst = 'instaQst';
                tableUser = 'insta';
            } else if (value === 'youtube') {
                tableQst = 'youtubeQst';
                tableUser = 'youtube';
            } else if (value === 'tiktok') {
                tableQst = 'tiktokQst';
                tableUser = 'tiktok';
            }
            try{
                const response = await fetch(`https://swingi.pro/api.php?action=getMediaQst&token=${encodeURIComponent(this.token)}&tableName=${tableQst}`, {
                method: 'GET', // Utilise GET puisque tu passes les données dans l'URL
                headers: {
                    'Content-Type': 'application/json'
                }
                });

                const response2 = await fetch(`https://swingi.pro/api.php?action=getUserMedia&token=${encodeURIComponent(this.token)}&tableName=${tableUser}`, {
                method: 'GET', // Utilise GET puisque tu passes les données dans l'URL
                headers: {
                    'Content-Type': 'application/json'
                }
                });

                const response3 = await fetch(`https://swingi.pro/api.php?action=getBankUser&token=${encodeURIComponent(this.token)}`, {
                method: 'GET', // Utilise GET puisque tu passes les données dans l'URL
                headers: {
                    'Content-Type': 'application/json'
                }
                });

                if (response.ok) {
                    const data = await response.json();
                    this.qst.push(data.data[2]);
                    this.discription.push(data.data[1]);
                    this.answer1.push(data.data[3]);
                    this.answer2.push(data.data[4]);
                    this.answer3.push(data.data[5]);
                    this.country.push(data.data[6]);
                    if (data.data[7] !== 1) {
                        this.active.push(false);
                    }
                    this.existing.push(true);
                    this.count = init;
                } else {
                    this.existing.push(false);
                    this.count = -1;
                }

                if (response2.ok) {
                    const data = await response2.json();
                    if (data.success) {
                        console.log(data);
                    if (value === 'insta') {
                        this.image.push(data.data[0].instaImage);
                        this.name.push(data.data[0].instaUser);
                        
                    } else if (value === 'youtube') {
                        this.image.push(data.data[0].youtubeImage);
                        this.name.push(data.data[0].youtubeUser);
                    } else if (value === 'tiktok') {
                        this.image.push(data.data[0].tikTokImage);
                        this.name.push(data.data[0].tikTokUser);
                    }
                    
                    } else {
                        console.error(data.message);
                    }
                    

                } else {
                    console.error('error', response2);
                }

                if (response3.ok) {
                    const data = await response3.json();
                    this.swingiPoint = data.data.sPoint;
                }

            } catch (e) {
                console.error(e);
            }
        }
    }
}



</script>


<style scoped>
.info-box {
    position: fixed;
    width: 200px;
    height: 200px;
    min-width: 200px;
    background: rgba(255, 255, 255, 0.8);
    border: 2px solid rgba(255, 255, 255, .2);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 10px #7fadc760;
    border-radius: 10px;
    left: 50%;
    top: 25%;
    transform: translate(-50%, 25%);
    z-index: 1000; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */
    display: flex;
    flex-direction: column; /* Assure que les éléments sont alignés verticalement */
    align-items: center;
  
  }

  .back-box {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(90, 90, 90, 0.5);
    left: 0;
    top: 0;
    display: flex;
    justify-content: center; /* Répartit les éléments avec de l'espace entre eux */
    align-items: center; /* Centre les éléments verticalement */

    z-index: 50; /* Ajoutez cette ligne pour s'assurer que le header est au premier plan */
  
  }

.hoverable {
  transition: background-color 0.3s ease; /* Optionnel : pour une transition douce */
}

.hoverable:hover {
  background-color: #ccc;
}

.hoverable2 {
    transition: background-color 0.3s ease; /* Optionnel : pour une transition douce */
  }
  
  .hoverable2:hover {
    background-color: #bdff8e;
  }
</style>

