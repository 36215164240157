<template>
    <div class="loginbody">
      <div class="wrapper">
        <form @submit.prevent="btnClick">
          <h1>Login</h1>
          <img class="fas fa-pen"/>
          <div class="important">
            <p>You have an ads blocker? Pleas think to add swingi.pro to your adblock white list.</p>
        </div>
          
          <div class="input-box">
            <input v-model="email" type="email" placeholder="Email" class="reveal-input" required>
            <img class="imageSvg" src="/icons/svg/Business/mail-fill.svg" alt="Artboard">  
          </div>
          <div class="input-box">
            <input :type="passwordFieldType" v-model="password" placeholder="Password" class="reveal-input" required/>
            <img v-if="passwordFieldType === 'password'" @click="togglePasswordVisibility" class="imageSvg" src="/icons/svg/System/lock-fill.svg" alt="Artboard">  
            <img v-if="passwordFieldType === 'text'" @click="togglePasswordVisibility" class="imageSvg" src="/icons/svg/System/lock-unlock-fill.svg" alt="Artboard">  
            
          </div>
  
          <div class="register-link">
            
            <a @click="sendResetEmail(email)"><button class="link-button">{{fPassword}}</button></a>
          </div>
          <div v-if="!isLogIn">
            <button type="submit" @click="logInWithEmail" class="btn">{{loginText}}</button>
          </div>
          <div v-else class="loader">
            <Loading type="submit" :width="'50px'"/>
          </div>
          

        </form>

        <div v-if="!isGoogleSignIn" @click="googleLogIn" class="google-signin-button">
          {{ googleLoginText }}
        </div>

        <div v-else class="loader">
          <Loading type="submit" :width="'50px'"/>
        </div>
        
      </div>

      <div class="signin" @click="login">
        <div :style="{fontSize: '10px'}">
          to
        </div>
        <div :style="{fontSize: '14px', fontWeight: 'bold'}"></div>
        SignIn
        </div>
        

    </div>
  </template>
  
  <script>
import { auth, provider } from '../firebase';
import { signInWithEmailAndPassword, signInWithPopup, sendPasswordResetEmail } from 'firebase/auth';
import Loading from '../components/items/loading.vue';



  export default {
    name: "LoginCard",

    components: {
      Loading,
  },

    data() {
      return {
        loginText: 'Log In With Swingi',
        googleLoginText: 'Google Log In',
        fPassword: 'Forgot Password ?',
        email: '',
        password: '',
        confirmPassword: '',
        passwordFieldType: 'password',
        displayedPassword: '',   // Ce qui est affiché à l'écran
        intervalId: null,         // Pour gérer le timing
        remember: false,
        isLogIn: false,
        isGoogleSignIn: false,
        verify: '',

      };
    },
    mounted() {
        this.revealEffect();  // Lance l'effet dès que le composant est monté
    },
    methods: {
      togglePasswordVisibility() {
        this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
        if (this.passwordFieldType !== 'password') {
          this.revealEffect();
        }
      },

      async verfyLogin(email) {
        const userDetails = {
          email: email,
        };


        const response = await fetch('http://swingi.pro/api.php?action=verifyLogin', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(userDetails)
          });
          
          if (!response.ok) {
            this.loginText = `HTTP error! Status: ${response.status}`;
            this.isLogIn = false;
          } else {
            const info = await response.json();
            console.log('info test: ', info);
            
            if (info.success === true) {
              this.verify = info.data;
            }
            this.isLogIn = false;
          }
              

              
      },

      async logInWithEmail() {
        this.isLogIn = true;
        try {
        const userCredential = await signInWithEmailAndPassword(auth, this.email, this.password);
        
        const user = userCredential.user;
        await this.verfyLogin(user.email);
        console.log('verify: ', this.verify);
        if (this.verify === '1') {
          localStorage.setItem('authToken', user.uid);
          localStorage.setItem('userEmail', user.email);
          localStorage.setItem('userName', localStorage.getItem('userName'));
          this.saveUserDetails(user.uid, localStorage.getItem('userName'), null, user.email, '0', '0');
          window.location.href = this.$router.resolve({ name: 'Home' }).href;
        } else {
          this.loginText = 'Cofirm Your Email, Then Log In';
        }
        
        this.isLogIn = false;
      } catch (error) {
        this.isLogIn = false;
        // Handle different types of errors
        switch (error.code) {
          case 'auth/email-already-in-use':
          this.loginText = 'Email address already in use.';
            break;
          case 'auth/invalid-email':
          this.loginText = 'The email address is not valid.';
            break;
          case 'auth/operation-not-allowed':
          this.loginText = 'Firebase enabling ERROR';
            break;
          case 'auth/weak-password':
          this.loginText = 'The password is too weak.';
          break;
          case 'auth/wrong-password':
          this.loginText = 'The password is incorrect.';
            break;
          default:
          this.loginText = 'Error registering user: RETRY';
        }
      }
    },

    wait(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        async revealEffect() {
          const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()-_=+[]{}|;:,.<>?';
            let randomText = '';
            
            const currentText = this.password;

            
              for (let i = 0; i < currentText.length; i++) {
                // Si une lettre du mot de passe existe, on l'affiche
                let randomL = '';
                let randomL2 = '';

                
                randomL += characters.charAt(Math.floor(Math.random() * characters.length));
                this.password = randomText + randomL;
                await this.wait(30);
                randomL2 += characters.charAt(Math.floor(Math.random() * characters.length));
                this.password = randomText;
                this.password = randomText + randomL2;
                await this.wait(30);
                randomText += currentText[i];  // Affiche la vraie lettre
                

                // Met à jour le texte affiché
              this.password = randomText;

            }


            
        },

    async googleLogIn() {
      this.isGoogleSignIn = true;
      const isLocal = window.location.hostname === 'localhost';

      if (isLocal) {
        
        localStorage.setItem('authToken', 'u1DQaW2YLQTXF1uAsg2T89WzzDD2');
            localStorage.setItem('userEmail', 'coussilaaliouane1999@gmail.com');
            localStorage.setItem('userName', localStorage.getItem('userName'));
            this.saveUserDetails('u1DQaW2YLQTXF1uAsg2T89WzzDD2', localStorage.getItem('userName'), null, 'coussilaaliouane1999@gmail.com', '0', '0');
            window.location.href = this.$router.resolve({ name: 'Home' }).href;
      } else {
        try {


          const userCredential = await signInWithPopup(auth, provider);


          const user = userCredential.user;
          await this.verfyLogin(user.email);


          if (this.verify === '1') {
              localStorage.setItem('authToken', user.uid);
              localStorage.setItem('userEmail', user.email);
              localStorage.setItem('userName', localStorage.getItem('userName'));
              this.saveUserDetails(user.uid, localStorage.getItem('userName'), null, user.email, '0', '0');
              window.location.href = this.$router.resolve({ name: 'Home' }).href;
            } else {
              this.googleLoginText = 'Cofirm Your Email, Then Log In';
            }
            this.isGoogleSignIn = false;
          } catch (error) {
            this.isGoogleSignIn = false;
            // Handle different types of errors
            switch (error.code) {
              case 'auth/email-already-in-use':
              this.googleLoginText = 'Email address already in use.';
                break;
              case 'auth/invalid-email':
              this.googleLoginText = 'The email address is not valid.';
                break;
              case 'auth/operation-not-allowed':
              this.googleLoginText = 'Firebase enabling ERROR';
                break;
              case 'auth/weak-password':
              this.googleLoginText = 'The password is too weak.';
                break;
              default:
              this.googleLoginText = 'Error registering user: RETRY', error;
          }
      }
      
      }


        
    },
      login() {
      this.$emit('login-click');
    },

    async saveUserDetails(token, userName, profileImage, email, phoneNumber, insta) {
  const userDetails = {
    token: token,
    userName: userName,
    profileImage: profileImage,
    email: email,
    phoneNumber: phoneNumber,
    insta: insta,
    dayTime: new Date().toISOString()
  };


  const response = await fetch('https://swingi.pro/api.php?action=getUserDetails');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const info = await response.json();
        const foundTokenIndex = info.data.data.findIndex(item => item.token === token);
        console.log('info1 : ', foundTokenIndex, token);

        if (foundTokenIndex !== -1) {
          await fetch('http://swingi.pro/api.php?action=saveUserDetails', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(userDetails)
      });
        }

}, 
    sendResetEmail(email) {
        sendPasswordResetEmail(auth, email)
            .then(() => {
                this.fPassword = 'Password Reset Email Sent';
                // Informer l'utilisateur que l'email a été envoyé
            })
            .catch((error) => {
              this.fPassword = 'Error in sending Email: ', error;
                // Gérer les erreurs (email non valide, utilisateur non trouvé, etc.)
            });
    }


    }
  };
  </script>
  
  <style scoped>
  /* Ajoutez des styles personnalisés si nécessaire */

  
  .loginbody {
    width: 80%;
    min-width: 300px;
    max-width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-block: 10px;
    flex-direction: column;
  }

  .signin {
    height: 50px;
    width: 50px;
    min-width: 50px;
    margin: 10px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    flex-direction: column;
  }

  .important {
    margin-top: 10px;
    width: 100%;
    
    font-size: 12px;
    text-align: center;
    color: #ff5555;
    
  }
  
  .wrapper h1 {
    font-weight: bold;
    font-size: 36px;
    text-align: center;
  }
  
  .wrapper .input-box {
    position: relative;
    width: 100%;
    height: 40px;
    margin: 20px 0;
  }

  .google-signin-button {
    margin-top: 10px;
    width: 100%;
    height: 45px;
    outline: none;
    border-radius: 6px;
    background: rgba(255, 255, 255, .5);
    border: 2px solid rgba(255, 255, 255, .8);
    backdrop-filter: blur(2px);
    cursor: pointer;
    font-size: 16px;
    color: black;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  

  .google-signin-button:hover {
    border-radius: 8px;
    padding: 2px;
    height: 45px;
    color: white;
      background: linear-gradient(45deg, #4285F480, #F4B40080, #0F9D5880);
      background-size: 300% 300%;
      animation: gradientAnimation 3s ease infinite;
  }
  
  @keyframes gradientAnimation {
      0% { background-position: 0% 50%; }
      50% { background-position: 100% 50%; }
      100% { background-position: 0% 50%; }
  }
  
  .input-box input {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .3);
    border: none;
    outline: none;
    border: 2px solid rgba(255, 255, 255, .8);
    border-radius: 6px;
    font-size: 16px;
    padding: 20px 45px 20px 20px;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
  }

  .input-box input:hover, input:focus {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .9);
    border: none;
    outline: none;
    border: 2px solid rgba(255, 255, 255, .4);
    border-radius: 6px;
    font-size: 16px;
    padding: 20px 45px 20px 20px;
  }
  
  .input-box input::placeholder {
    color: gray;
  }
  
  .input-box svg {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    cursor: pointer;
  }

  .input-box img {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    cursor: pointer;
  }
  
  .wrapper .remember-forgot {
    display: flex;
    justify-content: space-between;
    font-size: 14.5px;
    margin: -15px 0 15px;
  }
  
  .remember-forgot label input {
    accent-color: #fff;
    margin-right: 3px;
  }
  
  .remember-forgot a {
    color: gray;
    text-decoration: none;
  }
  
  .remember-forgot a:hover {
    text-decoration: underline;
  }
  
  .wrapper .btn {
    width: 100%;
    height: 45px;
    background: #fff;
    border: none;
    outline: none;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    cursor: pointer;
    font-size: 16px;
    color: black;
    font-weight: bold;
  }
  .btn:hover {
    border-radius: 8px;
    padding: 2px;
    height: 45px;
    color: white;
      background: linear-gradient(45deg, #fd1d1d80, #fcb04580, #833ab480, #fd1d1d80);
      background-size: 300% 300%;
      animation: gradientAnimation 5s ease infinite;
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  
  .wrapper .register-link {
    font-size: 12px;
    text-align: center;
    margin: 20px 0 15px;
  }
  
  .link-button {
    background: none;
    border: none;
    color: gray;
    cursor: pointer;
    text-decoration: underline;
    font-size: inherit;
  }
  
  .link-button:hover {
    color: #ff5555;
  }

  .reveal-input {
    font-size: 24px;
    color: black;
    background-color: transparent;
    padding: 10px;
    outline: none;
    width: 300px;
    border-radius: 5px;
    position: relative;
    transition: all 0.3s ease-in-out;
}

.imageSvg {
  width: 24px;
  height: 24px;
  background-size: contain;
}
  </style>
  