<template>
  <div class="bg1">
    <li class="list-none h-20"></li>

    <div v-if="!isMobile && !isLimited" :style="{
      width: '720px', 
      height: '90px', 
      cursor: 'pointer', 
      color: 'white', 
      fontFamily: 'Josefin Sans, sans-serif', 
      fontSize: '3vh', 
      fontWeight: 'bold',
      textAlign: 'center',
      marginBlock: '10px',
      padding: '5px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }" 
      @click="updateSwingiPoint('https://www.profitablecpmrate.com/ffumvexqt?key=0380ec4f99cfe042d93ffd7c6494635d')">
      <img src="/clickToSwing.png" alt="Swingi logo" />
    </div>
    <div v-if="isMobile && !isLimited" :style="{
      width: '320px', 
      height: '50px', 
      cursor: 'pointer', 
      color: 'white', 
      fontFamily: 'Josefin Sans, sans-serif', 
      fontSize: '2vh', 
      fontWeight: 'bold',
      textAlign: 'center',
      marginBlock: '10px',
      padding: '5px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }" 
      @click="updateSwingiPoint('https://www.profitablecpmrate.com/ffumvexqt?key=0380ec4f99cfe042d93ffd7c6494635d')">
      <img src="/clickToSwingMob.png" alt="Swingi logo" />
    </div>

    <div v-if="isLimited" :style="{
      width: '320px', 
      height: '50px', 
      cursor: 'pointer', 
      color: '#ff5555', 
      fontFamily: 'Josefin Sans, sans-serif', 
      fontSize: '2vh', 
      fontWeight: 'bold',
      textAlign: 'center',
      marginBlock: '10px',
      padding: '5px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }" 
      @click="updateSwingiPoint('https://www.profitablecpmrate.com/ffumvexqt?key=0380ec4f99cfe042d93ffd7c6494635d')">
      {{ limitedText }}
    </div>

    <!-- Conteneur de la publicité
    <div v-if="!isMobile" id="ad-container" >
      <AdScript1 :connexion="isOnline" :conStat="statInfo"/>
    </div>
    <div v-if="isMobile" id="ad-container" >
      <AdScript1Mob :connexion="isOnline" :conStat="statInfo"/>
    </div>
  -->
    <div class="sm:px-32 flex flex-wrap justify-center">
      <UserCard v-for="userD in usersD" :key="userD.id" :userInfo="userD" />
    </div>




    
  
    <div :style="{
            fontFamily: 'Josefin Sans, sans-serif', 
            fontSize: '12px', 
            width: '80%', 
            minWidth: '350px', 
            maxWidth: '700px',
            borderRadius: '5px',
            boxShadow: '0 0 10px #7fadc760',
            margin: '5px'
          }"
        >
        <li :style="{
          marginInline: '5px'
        }">
          <a>
            Add your social media accounts and complete your daily tasks to improve your ranking.
          </a>
          
        </li>
        <li :style="{
          marginInline: '5px'
        }">
          <a>
            The more SWINGI POINTS you have, the more you boost your audience.
          </a>
          
        </li>
          
        </div>

        <div  v-if="usersBanner[0]">
          <div
          
          :style="{ fontFamily: 'Josefin Sans, sans-serif', 
          fontSize: '14px', 
          display: 'flex', 
          justifyContent: 'center', 
          backgroundColor: 'gold', 
          width: '100%',
          minWidth: '350px', 
          maxWidth: '700px'}"
        >
        --The 4 Swingi Lord--
        </div>

        <div v-if="usersBanner[0]" >
          <!-- Desktop version -->
          <div v-if="!isMobile" :style="{width: '700px', border: '2px solid #ff5555', borderRadius: '5px', marginBlock: '10px', display: 'flex'}">
            <instaJob1 
              :usersInfo="usersBanner[0]"
            />
          </div>
          <!-- Mobile version -->
          <div v-else :style="{width: '350px', border: '2px solid #ff5555', borderRadius: '5px', marginBlock: '10px', display: 'flex'}">
            <instaJob1Mob 
              :usersInfo="usersBanner[0]"
            />
          </div>
        </div>
        </div>

        





      <div v-for="(item, index) in usersBanner" :key="index">
        <div 
          v-if="item.length > 0 & index > 0" 
          :style="{
            fontFamily: 'Josefin Sans, sans-serif', 
            fontSize: '14px', 
            display: 'flex', 
            justifyContent: 'center', 
            backgroundColor: 'gold', 
            width: '100%', 
            minWidth: '350px', 
            maxWidth: '700px'
          }"
        >
          --Top {{ 11 - index }} Ranked Swingi job--
        </div>
        
      
        <div v-if="item.length > 0 & index > 0">
          <!-- Desktop version -->
          <div v-if="!isMobile" :style="{width: '700px', marginBlock: '10px', display: 'flex'}">
            <instaJob1 
              :usersInfo="usersBanner[index]"
            />
          </div>
          <!-- Mobile version -->
          <div v-else :style="{width: '350px', marginBlock: '10px', display: 'flex'}">
            <instaJob1Mob 
              :usersInfo="usersBanner[index]"
            />
          </div>
        </div>


      </div>
      

      <!--
    <div v-if="instaName10.length > 0" :style="{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '14px', display: 'flex', justifyContent: 'center', backgroundColor: 'gold', width: '80%', minWidth: '350px', maxWidth: '700px'}">
      --Top 1 Ranked Swingi job--
    </div>
    
      <div v-if="!isMobile && instaName10.length > 0" >
        <instaJob1 :instaName="instaName10" :instaImage="instaImage10" :instaToken="instaToken10"/>
      </div>
      <div v-if="isMobile && instaName10.length > 0">
        <instaJob1Mob :instaName="instaName10" :instaImage="instaImage10" :instaToken="instaToken10"/>
      </div>

      <div v-if="instaName9.length > 0" :style="{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '14px', display: 'flex', justifyContent: 'center', backgroundColor: 'gold', width: '80%', minWidth: '350px', maxWidth: '700px'}">
        --Top 2 Ranked swingi job--
      </div>

      <div v-if="!isMobile && instaName9.length > 0" >
        <instaJob1 :instaName="instaName9" :instaImage="instaImage9" :instaToken="instaToken9"/>
      </div>
      <div v-if="isMobile && instaName9.length > 0">
        <instaJob1Mob :instaName="instaName9" :instaImage="instaImage9" :instaToken="instaToken9"/>
      </div>

      <div v-if="instaName8.length > 0" :style="{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '14px', display: 'flex', justifyContent: 'center', backgroundColor: 'gold', width: '80%', minWidth: '350px', maxWidth: '700px'}">
        --Top 3 Ranked swingi job--
      </div>

      <div v-if="!isMobile && instaName8.length > 0" >
        <instaJob1 :instaName="instaName8" :instaImage="instaImage8" :instaToken="instaToken8"/>
      </div>
      <div v-if="isMobile && instaName8.length > 0">
        <instaJob1Mob :instaName="instaName8" :instaImage="instaImage8" :instaToken="instaToken8"/>
      </div>

      <div v-if="instaName7.length > 0" :style="{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '14px', display: 'flex', justifyContent: 'center', backgroundColor: 'gold', width: '80%', minWidth: '350px', maxWidth: '700px'}">
        --Top 4 Ranked swingi job--
      </div>

      <div v-if="!isMobile && instaName7.length > 0" >
        <instaJob1 :instaName="instaName7" :instaImage="instaImage7" :instaToken="instaToken7"/>
      </div>
      <div v-if="isMobile && instaName7.length > 0">
        <instaJob1Mob :instaName="instaName7" :instaImage="instaImage7" :instaToken="instaToken7"/>
      </div>

      <div v-if="instaName6.length > 0" :style="{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '14px', display: 'flex', justifyContent: 'center', backgroundColor: 'gold', width: '80%', minWidth: '350px', maxWidth: '700px'}">
        --Top 5 Ranked swingi job--
      </div>

      <div v-if="!isMobile && instaName6.length > 0" >
        <instaJob1 :instaName="instaName6" :instaImage="instaImage6" :instaToken="instaToken6"/>
      </div>
      <div v-if="isMobile && instaName6.length > 0">
        <instaJob1Mob :instaName="instaName6" :instaImage="instaImage6" :instaToken="instaToken6"/>
      </div>

      <div v-if="instaName5.length > 0" :style="{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '14px', display: 'flex', justifyContent: 'center', backgroundColor: 'gold', width: '80%', minWidth: '350px', maxWidth: '700px'}">
        --Top 6 Ranked swingi job--
      </div>

      <div v-if="!isMobile && instaName5.length > 0" >
        <instaJob1 :instaName="instaName5" :instaImage="instaImage5" :instaToken="instaToken5"/>
      </div>
      <div v-if="isMobile && instaName5.length > 0">
        <instaJob1Mob :instaName="instaName5" :instaImage="instaImage5" :instaToken="instaToken5"/>
      </div>

      <div v-if="instaName4.length > 0" :style="{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '14px', display: 'flex', justifyContent: 'center', backgroundColor: 'gold', width: '80%', minWidth: '350px', maxWidth: '700px'}">
        --Top 7 Ranked swingi job--
      </div>

      <div v-if="!isMobile && instaName4.length > 0" >
        <instaJob1 :instaName="instaName4" :instaImage="instaImage4" :instaToken="instaToken4"/>
      </div>
      <div v-if="isMobile && instaName4.length > 0">
        <instaJob1Mob :instaName="instaName4" :instaImage="instaImage4" :instaToken="instaToken4"/>
      </div>

      <div v-if="instaName3.length > 0" :style="{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '14px', display: 'flex', justifyContent: 'center', backgroundColor: 'gold', width: '80%', minWidth: '350px', maxWidth: '700px'}">
        --Top 8 Ranked swingi job--
      </div>

      <div v-if="!isMobile && instaName3.length > 0" >
        <instaJob1 :instaName="instaName3" :instaImage="instaImage3" :instaToken="instaToken3"/>
      </div>
      <div v-if="isMobile && instaName3.length > 0">
        <instaJob1Mob :instaName="instaName3" :instaImage="instaImage9" :instaToken="instaToken3"/>
      </div>

      <div v-if="instaName2.length > 0" :style="{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '14px', display: 'flex', justifyContent: 'center', backgroundColor: 'gold', width: '80%', minWidth: '350px', maxWidth: '700px'}">
        --Top 9 Ranked swingi job--
      </div>

      <div v-if="!isMobile && instaName2.length > 0" >
        <instaJob1 :instaName="instaName2" :instaImage="instaImage2" :instaToken="instaToken2"/>
      </div>
      <div v-if="isMobile && instaName2.length > 0">
        <instaJob1Mob :instaName="instaName2" :instaImage="instaImage2" :instaToken="instaToken2"/>
      </div>

      <div v-if="instaName1.length > 0" :style="{ fontFamily: 'Josefin Sans, sans-serif', fontSize: '14px', display: 'flex', justifyContent: 'center', backgroundColor: 'gold', width: '80%', minWidth: '350px', maxWidth: '700px'}">
        --Top 10 swingi job--
      </div>

      <div v-if="!isMobile && instaName1.length > 0" >
        <instaJob1 :instaName="instaName1" :instaImage="instaImage1" :instaToken="instaToken1"/>
      </div>
      <div v-if="isMobile  && instaName1.length > 0">
        <instaJob1Mob :instaName="instaName1" :instaImage="instaImage1" :instaToken="instaToken1"/>
      </div>
    -->

   
  </div>


</template>

<script>
import UserCard from "../components/UserCard.vue";
//import AdScript1 from '../components/AdsScript1.vue';
//import AdScript1Mob from '../components/AdsScript1Mob.vue';
import instaJob1 from '../components/instaJob/instaJob1.vue';
import instaJob1Mob from '../components/instaJob/instaJob1Mob.vue';
import { onMounted } from 'vue';

export default {
  metaInfo: {
    title: 'Swingi Job',
    meta: [
      { name: 'description', content: 'Do your daily job and earn Swingi Point' },
      { name: 'keywords', content: 'vue, SEO, web development, earn, money, gagner, argent, creator, createurs, contents, contenue, algorithme' }
    ]
  },
  name: "HomeFeed",
  components: {
    UserCard,
    //AdScript1,
    instaJob1,
    instaJob1Mob,
    //AdScript1Mob,
  },
  props: {

  },
  data() {
    return {
      usersD: [],
      adClickHandled: false, // Flag to check if the ad click has been handled
      sPoint: 0,
      isMobile: false,
      listName: [],
      listToken: [],
      listImage: [],
      listSP: [],
      usersBanner: [],
      usersBanner0: [],
      usersBanner1: [],
      usersBanner2: [],
      usersBanner3: [],
      usersBanner4: [],
      usersBanner5: [],
      usersBanner6: [],
      usersBanner7: [],
      usersBanner8: [],
      usersBanner9: [],
      usersBanner10: [],

      list0: [],
      list1: [],
      list2: [],
      list3: [],
      list4: [],
      list5: [],
      list6: [],
      list7: [],
      list8: [],
      list9: [],
      list10: [],

      info: [],
      isLimited: false,
      limitedText: '',

      isOnline: navigator.onLine, // Vérifie si l'utilisateur est connecté au chargement
      statInfo: 'Pleas Add Swingi.pro To Your Ads Blocker White Liste, & Earn More Swingi Point.',
    };
  },
  setup() {
    // Appeler la fonction de création de l'overlay après le montage du composant
    onMounted(() => {
      if (window.createLemonSqueezy) {
        window.createLemonSqueezy(); // Initialiser l'overlay Lemon Squeezy
      } else {
        console.error("Lemon.js script not loaded");
      }
    });
  },
  created(){

    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);

    

    
  },
  async mounted() {

    this.updateOnlineStatus();

    if (this.isOnline) {
          this.statInfo = 'Pleas Add Swingi.pro To Your Ads Blocker White Liste, & Earn More Swingi Point.';
        } else {
          this.statInfo = 'Connexion Out, Pleas Reconnect';
        }
        // Détecter la taille de l'écran
    this.checkScreenSize();
    
    window.addEventListener('resize', this.checkScreenSize);

    await this.getInstaJob();

    this.usersBanner.push(this.list0, this.usersBanner1);

    
    
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkScreenSize);
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },



  methods: {

    async searchUser() {

    try {
      const response = await fetch('https://swingi.pro/api.php?action=getUserDetails');
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      
      this.info = await response.json();
      
      //this.foundTokenIndex = this.info.data.findIndex(item => item.token === this.userId);
      console.log('info1 : ', this.info);
      


      /*if (this.foundTokenIndex !== -1) {

        if (localStorage.getItem('userName')) {
          
          console.log('userName exist', localStorage.getItem('userName'));
        } else {
          if(this.info.data[this.foundTokenIndex].userName !== '' || this.info.data[this.foundTokenIndex].userName !== null) {
            //localStorage.setItem('userName', this.info.data[this.foundTokenIndex].userName);
          }
        }
        if (localStorage.getItem('proImage')) {
          
          console.log('proImage exist', localStorage.getItem('proImage'));
        } else {
          if(this.info.data[this.foundTokenIndex].profileImage !== '' || this.info.data[this.foundTokenIndex].profileImage !== null) {
            //localStorage.setItem('proImage', this.info.data[this.foundTokenIndex].profileImage);
          } else {
            //localStorage.setItem('proImage', '/g.png');
          }
        }

        

        
        
        
      }*/
      

      

      
    } catch (error) {
      this.info = { error: `Error: ${error.message}` };
    }
    },

    shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]; // Échange des éléments
    }
    return array;
},
    
    async updateSwingiPoint(url) {
      if (localStorage.getItem('authToken')) {
        
        try {
          var token = localStorage.getItem('authToken');

          const response0 = await fetch(`https://swingi.pro/api.php?action=updateBankValue`, {
            method: 'POST',

            body: JSON.stringify({ token })
          });

          if (!response0.ok) {
            throw new Error('Network response was not ok: ' + response0.statusText);
          }

            const data = await response0.json();

            if (!data || !data.success) {
              if (data.message === 'Please wait') {
                if (data.wait < 60) {
                    this.limitedText = `Please wait ${data.wait} seconds`;
                } else if (data.wait >= 60 && data.wait < 3600) {
                    const minutes = Math.floor(data.wait / 60);
                    this.limitedText = `Please wait ${minutes} minute${minutes > 1 ? 's' : ''}`;
                } else {
                    const hours = Math.floor(data.wait / 3600);
                    this.limitedText = `Please wait ${hours} hour${hours > 1 ? 's' : ''}`;
                }
                console.log('this.limitedText : ', this.limitedText);
                this.isLimited = true;
            } else {
              console.log('data.row : ', data.data);
              this.limitedText = data.message;
              this.isLimited = true;
            }
              
            } else {
              this.$emit('newSPoint', data.newSPoint);
              window.open(url, '_blank'); // '_blank' opens the link in a new tab
            }


        } catch (error) {
          console.error('Error during fetch:', error);
        }
      } else {
        this.$router.push({ name: 'UserD', params: { id: 0 } });
      }
      
    },


    updateOnlineStatus() {
      // Met à jour l'état en fonction de la connexion
      this.isOnline = navigator.onLine;
    },


    checkScreenSize() {
      this.isMobile = window.innerWidth <= 768; // Détecte si l'écran est inférieur ou égal à 768px (taille mobile)
    },


    async getInstaJob() {
      this.searchUser();
    try {
      var token = localStorage.getItem('authToken');
      
      const response = await fetch(`https://swingi.pro/api.php?action=media&tableName=insta`, {
        method: 'GET', // Utilise GET puisque tu passes les données dans l'URL
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const response2 = await fetch('https://swingi.pro/api.php?action=getInstaJob', {
        method: 'GET', // Utilise GET puisque tu passes les données dans l'URL
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const response3 = await fetch('https://swingi.pro/api.php?action=getBankDetails', {
        method: 'GET', // Utilise GET puisque tu passes les données dans l'URL
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok && !response2.ok ) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const data2 = await response2.json();
      const data3 = await response3.json();

      console.log(data3.data);

      if (data.error) {
        console.error('Erreur:', data.error, 'Détails:', data.details);
      } else {
      
      if (data.success === false){
        
        console.error('this is instaQst1:', data);
      } else {

        const actifInstaToken = [];

        for (var r = 0; r < data3.data.length; r++){
          const index = data2.data.tokens.findIndex(token => token === data3.data[r].token);
          console.log('index: ',index);
          if(index != -1) {
            
            actifInstaToken.push(data3.data[r]);
          }
        }
        

        // Combiner les tokens et les points en un seul tableau d'objets
        const combined = actifInstaToken.map(item => {
          return { token: item.token, sPoint: item.sPoint };
        });


        // Trier le tableau combiné en fonction des points
        const sortedCombined = combined.sort((a, b) => b.sPoint - a.sPoint);

        // Extraire les tokens et points triés
        //const sortedPoints = sortedCombined.map(item => item.sPoint);
        const sortedToken = sortedCombined.map(item => item.token);

        // Prendre les 4 plus grandes valeurs
        const top4Token = sortedToken.slice(0, 4);


        for (var i = 0; i < 4; i++) {
          const foundTokenIndex = this.info.data.findIndex(item => item.token === top4Token[i]);
          const foundItem = data3.data.find(item => item.token === top4Token[i]); 
          //const index = data.data.findIndex(item => item.token === top4Token[i]);
              
          // Vérifier que l'index est valide avant de pousser dans les tableaux
          if (foundTokenIndex !== -1) {
            
            var json = {
                    'name': this.info.data[foundTokenIndex].userName, 
                    'image': this.info.data[foundTokenIndex].profileImage, 
                    'token': this.info.data[foundTokenIndex].token, 
                    'sp': foundItem.sPoint};

                  this.list0.push(json);   
          }
        }


          
        for(var v = 0; v < this.info.data.length; v++){
          const foundIndex = data2.data.tokens.findIndex(token => token === this.info.data[v].token);
          const foundItem = data3.data.find(item => item.token === this.info.data[v].token);       
          
          
            if (foundIndex !== -1) {

              if (foundItem.sPoint >= 0 && foundItem.sPoint <= 5000 && this.info.data[v].token != token){
                if(data2.data.activ[foundIndex] === '1') {

                  var json1 = {
                    'name': this.info.data[v].userName, 
                    'image': this.info.data[v].profileImage, 
                    'token': this.info.data[v].token, 
                    'sp': foundItem.sPoint};

                  this.list1.push(json1);
                      

                }
              } else if (foundItem.sPoint > 5000 && foundItem.sPoint <= 10000 && this.info.data[v].token != token) {
                if(data2.data.activ[foundIndex] === '1') {

                  var json2 = {
                    'name': this.info.data[v].userName, 
                    'image': this.info.data[v].profileImage, 
                    'token': this.info.data[v].token, 
                    'sp': foundItem.sPoint};

                  this.list2.push(json2);
                }
              } else if (foundItem.sPoint > 10000 && foundItem.sPoint <= 20000 && this.info.data[v].token != token) {
                if(data2.data.activ[foundIndex] === '1') {

                  var json3 = {
                    'name': this.info.data[v].userName, 
                    'image': this.info.data[v].profileImage, 
                    'token': this.info.data[v].token, 
                    'sp': foundItem.sPoint};

                  this.list3.push(json3);
                }
              } else if (foundItem.sPoint > 20000 && foundItem.sPoint <= 50000 && this.info.data[v].token != token) {
                if(data2.data.activ[foundIndex] === '1') {

                  var json4 = {
                    'name': this.info.data[v].userName, 
                    'image': this.info.data[v].profileImage, 
                    'token': this.info.data[v].token, 
                    'sp': foundItem.sPoint};

                  this.list4.push(json4);
                }
              } else if (foundItem.sPoint > 50000 && foundItem.sPoint <= 100000 && this.info.data[v].token != token) {
                if(data2.data.activ[foundIndex] === '1') {

                  var json5 = {
                    'name': this.info.data[v].userName, 
                    'image': this.info.data[v].profileImage, 
                    'token': this.info.data[v].token, 
                    'sp': foundItem.sPoint};

                  this.list5.push(json5);
                }
              } else if (foundItem.sPoint > 100000 && foundItem.sPoint <= 200000 && this.info.data[v].token != token) {
                if(data2.data.activ[foundIndex] === '1') {

                  var json6 = {
                    'name': this.info.data[v].userName, 
                    'image': this.info.data[v].profileImage, 
                    'token': this.info.data[v].token, 
                    'sp': foundItem.sPoint};

                  this.list6.push(json6);
                }
              } else if (foundItem.sPoint > 200000 && foundItem.sPoint <= 400000 && this.info.data[v].token != token) {
                if(data2.data.activ[foundIndex] === '1') {

                  var json7 = {
                    'name': this.info.data[v].userName, 
                    'image': this.info.data[v].profileImage, 
                    'token': this.info.data[v].token, 
                    'sp': foundItem.sPoint};

                  this.list7.push(json7);
                }
              } else if (foundItem.sPoint > 400000 && foundItem.sPoint <= 800000 && this.info.data[v].token != token) {
                if(data2.data.activ[foundIndex] === '1') {

                  var json8 = {
                    'name': this.info.data[v].userName, 
                    'image': this.info.data[v].profileImage, 
                    'token': this.info.data[v].token, 
                    'sp': foundItem.sPoint};

                  this.list8.push(json8);
                }
              } else if (foundItem.sPoint > 800000 && foundItem.sPoint <= 1000000 && this.info.data[v].token != token) {
                if(data2.data.activ[foundIndex] === '1') {

                  var json9 = {
                    'name': this.info.data[v].userName, 
                    'image': this.info.data[v].profileImage, 
                    'token': this.info.data[v].token, 
                    'sp': foundItem.sPoint};

                  this.list9.push(json9);
                }
              } else if (foundItem.sPoint > 1000000) {
                if(data2.data.activ[foundIndex] === '1') {

                  var json10 = {
                    'name': this.info.data[v].userName, 
                    'image': this.info.data[v].profileImage, 
                    'token': this.info.data[v].token, 
                    'sp': foundItem.sPoint};

                  this.list10.push(json10);
                }
              } 
                
              
          }
          
        }
        

          if (this.list1) {
            const shuffledList1 = this.shuffleArray(this.list1);
            this.usersBanner1 = shuffledList1;
            console.log('this.usersBanner1', this.usersBanner1);
          }
          /*
          if (this.instaName1) {
            const shuffledList1 = shuffleArray(this.instaName1);
          }
          if (this.instaName2) {
            const shuffledList2= shuffleArray(this.instaName2);
          }
          if (this.instaName3) {
            const shuffledList3= shuffleArray(this.instaName3);
          }
          if (this.instaName4) {
            const shuffledList4= shuffleArray(this.instaName4);
          }
          if (this.instaName5) {
            const shuffledList5= shuffleArray(this.instaName5);
          }
          if (this.instaName6) {
            const shuffledList6= shuffleArray(this.instaName6);
          }
          if (this.instaName7) {
            const shuffledList7= shuffleArray(this.instaName7);
          }
          if (this.instaName8) {
            const shuffledList8= shuffleArray(this.instaName8);
          }
          if (this.instaName9) {
            const shuffledList9= shuffleArray(this.instaName9);
          }
          if (this.instaName10) {
            const shuffledList10 = shuffleArray(this.instaName10);
          }*/

          


        
        
      }
    }
    

  } catch (error) {
    console.error('Erreur lors de la requête POST:', error);
    this.info = { error: `Error: ${error.message}` };
  }

},




  },
  
  
};
</script>

<style>
#ad-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  padding: 5px;
}

/* Style personnalisé pour le bouton */
.lemonsqueezy-button {
  width: 200px;
  background-color: #f4c152;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lemonsqueezy-button:hover {
  background-color: #e0ac3d;
}

.bg1 {
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}







</style>
